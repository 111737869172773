import { Checkbox } from "@/components/primitives/Checkbox";
import FieldLabel from "@/components/primitives/InputLabel";
import { FormType } from "@/integrations";
import { Description } from "@/pages/Setup/components/Typography";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect } from "react";

type Props = {
  field: Extract<FormType, { type: "checkbox" }>;
};

export default function CheckboxInput({ field }: Props) {
  const {
    name: id,
    inputProps,
    required,
    label,
    description,
    value: defaultValue,
  } = field;

  const { control, setValue } = useFormContext();

  useEffect(() => {
    if (defaultValue) {
      setValue(id, defaultValue);
    }
  }, [id, setValue, defaultValue]);

  return (
    <div className="pb-4">
      <FieldLabel id={id} required={required}>
        {label}
      </FieldLabel>
      <Description className="text-xs">{description}</Description>
      <Controller
        control={control}
        defaultValue={defaultValue ?? false}
        render={({ field: { onChange, onBlur, value } }) => (
          <Checkbox
            checked={value}
            onBlur={onBlur}
            onChange={(v: any) => onChange(v)}
            name={field.name}
            {...inputProps}
          />
        )}
        rules={{ required }}
        name={id}
      />
    </div>
  );
}
