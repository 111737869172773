import { useState } from "react";

import { Button, PrimaryButton } from "@/components/elements/Button";
import { CodeDisplay } from "@/components/elements/CodeDisplay";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";
import { stringToUniqueId } from "@/helpers/stringToUniqueId";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { useTimeout } from "@/hooks/useTimeout";
import { useEditorState } from "@/pages/ModelTool/EditorStore";
import { usePreview } from "@/pages/ModelTool/Preview/usePreview";
import { useCreateDraftModel } from "@/pages/ModelTool/QueryEditor/useModelDraft";
import {
  extractWeldTags,
  useGetQueryDependencies,
} from "@/pages/ModelTool/useQueryDependencies";
import { useDarkMode } from "@/providers/DarkModeProvider";
import { PlusIcon } from "@heroicons/react/16/solid";
import { Square2StackIcon } from "@heroicons/react/24/outline";
import { CheckIcon, PlayIcon } from "@heroicons/react/24/solid";
import { ComponentProps } from "@tw-classed/react";

export const SQLDisplayBox = (props: {
  sql: string;
  loading?: boolean;
  chatThreadId?: string;
}) => {
  const preview = usePreview();
  const createDraftModel = useCreateDraftModel();
  const openModelInTab = useOpenModelInTab();
  const { isDarkModeEnabled } = useDarkMode();

  const [editorState] = useEditorState();

  const getQueryDependencies = useGetQueryDependencies();

  const [, copy] = useCopyToClipboard();
  return (
    <div className="space-y-2 pl-9 pt-2">
      <CodeDisplay
        language="sql"
        showLineNumber={false}
        code={props.sql}
        customStyle={{
          backgroundColor: isDarkModeEnabled
            ? "rgb(19 21 22)"
            : "rgb(248 248 248)",
          borderRadius: "0.75rem",
          padding: "1rem",
          overflow: "auto",
          maxHeight: "24rem",
          fontSize: "0.75rem",
          border: "1px solid",
          borderColor: isDarkModeEnabled ? "rgb(31 32 35)" : "rgb(229 231 235)",
        }}
      />

      <div className="flex items-stretch justify-start">
        {editorState.initialized &&
          editorState.currentEditorState !== "dashboard" && (
            <>
              <PrimaryButton
                size="sm"
                className="mr-2"
                isLoading={props.loading}
                loadingText="Generating&hellip;"
                onClick={() => {
                  preview({
                    sqlQuery: props.sql,
                    dependencies: getQueryDependencies(
                      extractWeldTags(props.sql),
                    ),
                    modelId: stringToUniqueId(props.sql),
                    isTextSelection: false,
                    modelName: "SQL assistant query",
                    modelType: "draft",
                    chatTreadId: props.chatThreadId,
                  });
                }}
                iconRight={<PlayIcon />}
              >
                Run
              </PrimaryButton>
              <CopyButton
                className="mr-2"
                size="sm"
                variant="outline"
                colorScheme="secondary"
                disabled={props.loading}
                onClick={() => {
                  copy(props.sql);
                }}
              />
            </>
          )}

        <Button
          size="sm"
          variant="outline"
          colorScheme="secondary"
          disabled={props.loading}
          onClick={() => {
            const draft = createDraftModel(props.sql);
            openModelInTab({ modelId: draft.id, type: "draft" });
          }}
          icon={<PlusIcon />}
        >
          New Model
        </Button>
      </div>
    </div>
  );
};

function CopyButton(props: ComponentProps<typeof Button>) {
  const [copied, setCopied] = useState(false);

  useTimeout(
    () => {
      setCopied(false);
    },
    copied ? 2000 : null,
  );

  return (
    <Button
      {...props}
      onClick={(e) => {
        setCopied(true);
        props.onClick?.(e);
      }}
      icon={copied ? <CheckIcon /> : <Square2StackIcon />}
    >
      {copied ? "Copied!" : "Copy Code"}
    </Button>
  );
}
