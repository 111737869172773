import { useFlags } from "launchdarkly-react-client-sdk";
import { ComponentPropsWithoutRef, useEffect } from "react";

import { Banner } from "@/components/elements/Banner";
import { Button, ButtonGroup } from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import { WELD_CONTRACT_RENEWAL_CALL_URL } from "@/constants/external-urls/hubspot";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useMixpanel } from "@/monitoring/mixpanel";
import { CalendarDaysIcon } from "@heroicons/react/16/solid";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { ComponentProps } from "@tw-classed/react";

import { WorkspaceAlert, WorkspaceAlertResolver } from "../useWorkspaceAlerts";

type Status = NonNullable<ComponentProps<typeof Banner>["status"]>;
const allowedStatuses: Status[] = ["info", "warning", "error"];

function useNotificationFromLaunchDarkly():
  | (WorkspaceAlert & { details?: string })
  | null {
  const { contractRenewalReminderBanner: bannerOptions } = useFlags();
  if (typeof bannerOptions?.message === "string") {
    let status: Status = "info";
    if (
      bannerOptions.status &&
      allowedStatuses.includes(bannerOptions.status as Status)
    ) {
      status = bannerOptions.status as Status;
    }
    return {
      id: "contract-renewal-reminder",
      title: bannerOptions.title ?? "Contract Renewal Reminder",
      message: bannerOptions.message,
      details: bannerOptions.details,
      status,
      isDismissable: status !== "error",
      alwaysShow: true,
    };
  }
  return null;
}

export const useContractRenewalNotificationResolver: WorkspaceAlertResolver =
  () => {
    const notification = useNotificationFromLaunchDarkly();
    if (!notification) {
      return [];
    }
    return [
      {
        ...notification,
        action: notification.details ? (
          <ContractRenewalActionButton notification={notification} />
        ) : null,
      },
    ];
  };

function BookMeetingButton(props: ComponentPropsWithoutRef<typeof Button>) {
  return (
    <Button
      {...props}
      variant="solid"
      colorScheme="primary"
      icon={<CalendarDaysIcon />}
      as="a"
      href={WELD_CONTRACT_RENEWAL_CALL_URL}
      target="_blank"
      rel="noreferrer"
    >
      Book a Meeting
    </Button>
  );
}

function ContractRenewalActionButton(props: {
  notification: NonNullable<ReturnType<typeof useNotificationFromLaunchDarkly>>;
}) {
  const disclosure = useDisclosure();
  const mp = useMixpanel();
  return (
    <>
      <ButtonGroup size="xs" className="gap-2">
        <Button
          onClick={() => disclosure.onOpen()}
          size="xs"
          variant="solid"
          colorScheme="secondary"
          className="border-black/20 dark:border-white/20"
          icon={<InformationCircleIcon />}
        >
          More info
        </Button>
        <BookMeetingButton
          onClick={() => {
            mp.track("Book Contract Renewal Meeting Clicked", {
              location: "contract-renewal-banner",
            });
          }}
        />
      </ButtonGroup>
      <ContractRenewalDialog
        notification={props.notification}
        {...disclosure}
      />
    </>
  );
}

function ContractRenewalDialog({
  notification,
  ...props
}: ComponentProps<typeof Modal> & {
  notification: NonNullable<ReturnType<typeof useNotificationFromLaunchDarkly>>;
}) {
  const mp = useMixpanel();

  useEffect(() => {
    if (props.isOpen) {
      mp.track("Contract Renewal Dialog Opened");
    }
  }, [props.isOpen, mp]);
  return (
    <Modal {...props}>
      <ModalHeader>{notification.title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <p>{notification.details}</p>
      </ModalBody>
      <ModalFooter>
        <BookMeetingButton
          onClick={() => {
            mp.track("Book Contract Renewal Meeting Clicked", {
              location: "contract-renewal-dialog",
            });
          }}
        />
      </ModalFooter>
    </Modal>
  );
}
