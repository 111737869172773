import { InputDescription } from "@/components/primitives/InputDescription";
import { InputError } from "@/components/primitives/InputError";
import { InputErrorIndicator } from "@/components/primitives/InputErrorIndicator";
import FieldLabel from "@/components/primitives/InputLabel";
import { Input } from "@/components/primitives/input";
import { FormType } from "@/integrations";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect } from "react";

export default function FormTextInput({
  field,
}: {
  field: Extract<FormType, { type: "text" | "email" | "number" | "password" }>;
}) {
  const { formState, control, trigger } = useFormContext();

  const { name, inputProps, type, required, label, pattern } = field;
  const error = formState.errors?.[name]?.message;

  const validate = () => {
    return field.error;
  };

  useEffect(() => {
    void trigger(field.name);
  }, [field.name, field.error, trigger]);

  return (
    <div className="pb-4">
      <FieldLabel id={name} required={required}>
        {label}
      </FieldLabel>

      <div className="relative">
        <Controller
          control={control}
          defaultValue={field.value ?? ""}
          render={({ field }) => (
            <Input
              {...field}
              type={type}
              name={field.name}
              className={!!error ? "pr-12" : ""}
              {...inputProps}
            />
          )}
          rules={{ required, pattern, validate }}
          name={name}
        />

        {!!error && (
          <span className="absolute right-4 top-1/2 w-7 -translate-y-1/2 transform text-green-500">
            <InputErrorIndicator className="ml-2" />
          </span>
        )}
      </div>
      {!!field.description && (
        <InputDescription className="m-1">
          <>{field.description}</>
        </InputDescription>
      )}
      {!!error && <InputError className="mt-1">{String(error)}</InputError>}
    </div>
  );
}
