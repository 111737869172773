import dayjs from "dayjs";
import { useMemo } from "react";

import {
  SubscriptionStatus,
  useStripeSubscriptionLazyQuery,
  useStripeSubscriptionQuery,
} from "@/apollo/types";
import { useSubscriptionStatus } from "@/features/subscription";
import useNow from "@/hooks/useNow";
import { useCurrentAccount } from "@/providers/account";

export function useFreeMonthDiscount() {
  const { status } = useSubscriptionStatus();
  const now = useNow(60 * 60 * 1000); // 1 hour
  return {
    isEligible:
      status === SubscriptionStatus.Freemium &&
      now.isBefore(dayjs.utc("2025-01-01").endOf("day")),
  };
}

export function useFreeDataConsultationOffer() {
  const account = useCurrentAccount();
  const { data } = useStripeSubscriptionQuery({
    variables: {
      slug: account.slug,
    },
  });
  const [fetchSubscription] = useStripeSubscriptionLazyQuery({
    variables: {
      slug: account.slug,
    },
  });

  const now = useNow(60 * 60 * 1000); // 1 hour

  return useMemo(() => {
    const isDecemberCustomer = (createdAt: dayjs.Dayjs) => {
      if (createdAt.isBefore(dayjs.utc("2024-11-30").startOf("day"))) {
        return false;
      }
      return createdAt.isBefore(dayjs.utc("2025-01-01").endOf("day"));
    };

    const verifyDecemberCustomer = (
      data: Awaited<ReturnType<typeof fetchSubscription>>["data"],
    ) => {
      if (!data || data.accountBySlug.stripeSubscription == null) {
        return false;
      }
      const createdAt = dayjs(
        data.accountBySlug.stripeSubscription.stripeSubscriptionDetails
          .createdDate,
      );
      return isDecemberCustomer(createdAt);
    };

    const isCampaignActive = (now: dayjs.Dayjs) => {
      return now.isBefore(dayjs.utc("2025-01-01").endOf("day"));
    };

    return {
      isEligible: isCampaignActive(now) && verifyDecemberCustomer(data),
      testEligibility: async () => {
        const { data } = await fetchSubscription();
        const now = dayjs.utc();
        if (isCampaignActive(now)) {
          return verifyDecemberCustomer(data);
        }
        return false;
      },
    };
  }, [data, fetchSubscription, now]);
}
