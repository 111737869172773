import dayjs from "dayjs";

import { ListModelsQuery } from "@/apollo/types";
import Tooltip from "@/components/elements/Tooltip";
import UserAvatar from "@/components/elements/UserAvatar";
import { CurrentUserAvatar } from "@/components/modules/CurrentUserAvatar";
import classNames from "@/helpers/classNames";
import { useCurrentUser } from "@/providers/UserProvider";
import { CheckIcon } from "@heroicons/react/20/solid";

import { SqlHistoryItem } from "../../hooks/useQueryHistory";
import HistoryLine from "./HistoryLine";

export default function HistoryButton(props: {
  open: boolean;
  selected: boolean;
  index: number;
  historyItem: SqlHistoryItem;
  isLast: boolean;
  model?: ListModelsQuery["models"][0];
  onClick: () => any;
}) {
  const isPublished = props.historyItem.id === props.model?.publishedQuery?.id;

  const isLocalMaterializedJob =
    props.model?.localMaterializationJob?.queryId === props.historyItem.id;

  const isSynced =
    props.model?.dwTable?.queryId === props.historyItem.id ||
    props.model?.dwSync?.queryId === props.historyItem.id ||
    (!!props.model?.dwTable && isPublished); //added as hack to fix issue deprecated data for old published tables. Remove after new materialization done....

  return (
    <li
      className={`relative p-2 ${
        props.selected ? "bg-gray-100 dark:bg-gray-900" : ""
      }`}
    >
      {!props.isLast ? <HistoryLine /> : null}
      <button
        className="group relative flex w-full items-center space-x-4 p-2 text-left"
        onClick={() => props.onClick()}
      >
        <span className="flex h-9 items-center">
          <HistoryIcon
            isPublished={isPublished}
            isLocalMaterializedJob={isLocalMaterializedJob}
            isSynced={isSynced}
          />
        </span>
        <span
          className={`flex min-w-0 grow flex-col transition-opacity ${
            props.open ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="flex items-center justify-between space-x-1">
            <span className="text-xs font-semibold uppercase tracking-wide text-gray-700 dark:text-gray-200">
              {dayjs().diff(dayjs(props.historyItem.createdAt), "minute") < 60
                ? dayjs(props.historyItem.createdAt).fromNow()
                : dayjs(props.historyItem.createdAt).format(
                    "HH:mm DD. MMM YYYY",
                  )}
            </span>
          </div>
          <span className="truncate text-xs text-muted-foreground">
            {props.historyItem.weldSql}
          </span>
        </span>
        <UserTag user={props.historyItem.user} />
      </button>
    </li>
  );
}

const HistoryIcon = (props: {
  isPublished: boolean;
  isLocalMaterializedJob: boolean;
  isSynced: boolean;
}) => {
  if (props.isPublished)
    return (
      <span
        className={classNames(
          props.isSynced
            ? "border-blue-600 bg-blue-100 dark:bg-blue-600"
            : props.isLocalMaterializedJob
              ? "bg-blue-400"
              : "bg-gray-400 group-hover:bg-gray-500",
          "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border text-primary dark:text-white",
        )}
      >
        {!props.isSynced ? (
          <Tooltip
            content={
              props.isLocalMaterializedJob
                ? "Materialization has not completed yet"
                : "Query history out of sync with datawarehouse, try publishing again"
            }
          >
            <div>
              <CheckIcon className="h-4 w-4" aria-hidden="true" />
            </div>
          </Tooltip>
        ) : (
          <CheckIcon className="h-4 w-4" aria-hidden="true" />
        )}
      </span>
    );
  return (
    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400 dark:border-gray-700 dark:bg-gray-800">
      <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
    </span>
  );
};

const UserTag: React.FC<{
  user: SqlHistoryItem["user"];
}> = (props) => {
  const currentUser = useCurrentUser();
  if (!props.user?.firstName && !props.user?.lastName) return null;
  return (
    <Tooltip
      content={
        <div className="flex flex-col text-xs">
          <span className="font-bold">
            {props.user.firstName} {props.user.lastName}
          </span>
          <span>{props.user.email}</span>
        </div>
      }
    >
      <div>
        {props.user.email === currentUser.email ? (
          <CurrentUserAvatar />
        ) : (
          <UserAvatar
            firstName={props?.user?.firstName}
            lastName={props?.user?.lastName}
          />
        )}
      </div>
    </Tooltip>
  );
};
