import { useConnectionWithCredentialsQuery } from "@/apollo/types";
import { WeldTab } from "@/components/elements/tabs/Tab";
import { IntegrationLogo } from "@/integrations";
import { LinkWithSlug, LocationGenerics } from "@/routes";
import { Tab } from "@headlessui/react";
import { Outlet, useMatch, useMatchRoute } from "@tanstack/react-location";

import { SettingsPageLayout } from "../Settings/PageLayout";

export function ViewConnectionLayout() {
  const match = useMatch<LocationGenerics>();
  const matchRoute = useMatchRoute();

  const { data } = useConnectionWithCredentialsQuery({
    variables: {
      id: match.params.connectionId,
    },
  });
  const connection = data?.connection;

  if (!connection) {
    return null;
  }

  const selectedIndex = matchRoute({
    to: `./configuration`,
  })
    ? 1
    : 0;

  return (
    <SettingsPageLayout
      renderHeader={() => {
        return (
          <header>
            <div className="flex min-h-10 items-center gap-3 text-xl">
              <LinkWithSlug
                to={`/settings/connections`}
                className="whitespace-nowrap opacity-50 hover:underline focus:outline-none dark:hover:text-gray-300"
              >
                Connectors
              </LinkWithSlug>
              <Slash />
              <div className="inline-flex items-center gap-3">
                <IntegrationLogo
                  id={connection.integrationId}
                  className="h-5 w-5"
                />
                {connection.label}
              </div>
            </div>
          </header>
        );
      }}
    >
      <Tab.Group
        selectedIndex={selectedIndex}
        as="div"
        className="flex h-full flex-col"
      >
        <Tab.List className="mb-6 flex gap-8">
          <WeldTab
            as={LinkWithSlug}
            to={`/settings/connections/${connection.id}`}
          >
            Overview
          </WeldTab>
          <WeldTab
            as={LinkWithSlug}
            to={`/settings/connections/${connection.id}/configuration`}
          >
            Configuration
          </WeldTab>
        </Tab.List>
        <Tab.Panels className="grow">
          <Tab.Panel className="h-full">
            <Outlet />
          </Tab.Panel>
          <Tab.Panel className="h-full">
            <Outlet />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </SettingsPageLayout>
  );
}

const Slash = () => (
  <svg
    className="h-5 w-5 shrink-0 fill-current text-gray-300"
    viewBox="0 0 20 20"
    aria-hidden="true"
  >
    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
  </svg>
);
