import { ComponentProps } from "react";

import { useDarkMode } from "@/providers/DarkModeProvider";
import { useCurrentUser } from "@/providers/UserProvider";

import UserAvatar from "../elements/UserAvatar";

export function CurrentUserAvatar(
  props: Omit<
    ComponentProps<typeof UserAvatar>,
    "backgroundColor" | "firstName" | "lastName"
  >,
) {
  const user = useCurrentUser();
  const { isDarkModeEnabled } = useDarkMode();
  return (
    <UserAvatar
      firstName={user.firstName}
      lastName={user.lastName}
      backgroundColor={isDarkModeEnabled ? "#0369a1" : "#0ea5e9"}
      {...props}
    />
  );
}
