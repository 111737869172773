import { useMemo } from "react";

import { ChatRole } from "@/apollo/types";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { useFormatWeldSQL } from "@/pages/ModelTool/hooks/useFormatWeldSQL";

import { ChatMessage } from "../types";
import { DialogBox } from "./DialogBox";
import { SQLDisplayBox } from "./SQLDisplay";

export function ChatMessages(props: {
  messages?: ChatMessage[];
  isStreamingResponse?: boolean;
  chatThreadId?: string;
}) {
  return (
    <>
      {props.messages?.map((m, i) => {
        const isLast = props.messages && i === props.messages.length - 1;
        if (m.role === ChatRole.Assistant) {
          return (
            <AIDisplayMessage
              key={i}
              message={m}
              loading={props.isStreamingResponse && isLast}
              chatThreadId={props.chatThreadId}
            />
          );
        }
        return (
          <DialogBox key={i} role={m.role}>
            {m.content}
          </DialogBox>
        );
      })}
    </>
  );
}

type MessageWithCode = ChatMessage & { isCode?: boolean };

const AIDisplayMessage = (props: {
  message: ChatMessage;
  loading?: boolean;
  chatThreadId?: string;
}) => {
  const showEmptyLoadingSpinner =
    props.loading && props.message.content.length === 0;

  //check for code wrapped with ""```"" or ""```sql"
  const messages = useMessagesSplitForCode(props.message);

  return (
    <div className="space-y-2">
      {messages.map((m, i, arr) => {
        if (m.isCode) {
          return (
            <SQLDisplayBox
              key={m.id}
              sql={m.content}
              loading={props.loading && i === arr.length - 1}
              chatThreadId={props.chatThreadId}
            />
          );
        }
        return (
          <DialogBox key={m.id} role={ChatRole.Assistant}>
            {m.content}
            {showEmptyLoadingSpinner && <LoadingSpinner className="h-4 w-4" />}
          </DialogBox>
        );
      })}
    </div>
  );
};

const useMessagesSplitForCode = (message: ChatMessage): MessageWithCode[] => {
  const formatWeldSQL = useFormatWeldSQL();

  //check for code wrapped with ""```"" or ""```sql"
  const messages: Omit<MessageWithCode, "id">[] = useMemo(() => {
    const newMessages: Omit<MessageWithCode, "id">[] = [];

    //Check for multiple code matches:
    const codeRegex = /```[\s\S]*?```/g;
    const codeMessages = message.content.match(codeRegex);

    if (!codeMessages || codeMessages.length === 0) {
      //If theres no code segments, check if the message is code
      //For now, we assume that if the message starts with "SELECT" it is code (not perfect)
      const isCode = message.content.toLocaleLowerCase().startsWith("select");
      if (isCode) {
        const trimmedCode = message.content
          .replaceAll(/```(sql)?/g, "")
          .replaceAll(/```/g, "")
          .trim();
        const formattedCode = formatWeldSQL(trimmedCode);
        return [{ ...message, content: formattedCode, isCode: isCode }];
      }
      return [message];
    }

    //Contain the messages that are code
    const textMessages = message.content.split(codeRegex);

    //Check if the message only contains code
    if (textMessages.length === 0) {
      const trimmedCode = codeMessages[0]
        .replaceAll(/```(sql)?/g, "")
        .replaceAll(/```/g, "")
        .trim();
      const formattedCode = formatWeldSQL(trimmedCode);
      return [
        {
          content: formattedCode,
          role: message.role,
          isCode: true,
        },
      ];
    }

    //split the message into code and text
    textMessages.forEach((msg, i) => {
      const trimmedMsg = msg.trim();
      //check if the message is empty
      if (trimmedMsg.length > 0) {
        newMessages.push({
          content: trimmedMsg,
          role: message.role,
        });
      }

      const code = codeMessages[i];
      if (code) {
        const trimmedCode = code
          .replaceAll(/```(sql)?/g, "")
          .replaceAll(/```/g, "")
          .trim();
        const formattedCode = formatWeldSQL(trimmedCode);
        newMessages.push({
          content: formattedCode,
          role: message.role,
          isCode: true,
        });
      }
    });

    return newMessages;
  }, [formatWeldSQL, message]);

  //Check if this is the last message and not followed by code
  //If so, check if the message is a partial code block
  const lastMessage = messages[messages.length - 1];
  const lastMessageContent = lastMessage.content;
  const lastMsgSplitByCodeStart = lastMessageContent.split(/```/g);
  if (!lastMessage.isCode && lastMsgSplitByCodeStart.length > 1) {
    //Overwrite the last message with code and message
    messages[messages.length - 1] = {
      content: lastMsgSplitByCodeStart[0],
      role: lastMessage.role,
    };

    let code = lastMsgSplitByCodeStart[1];
    if (code.toLowerCase().startsWith("sql")) {
      code = code.slice(3);
    }
    messages.push({
      content: formatWeldSQL(code),
      role: lastMessage.role,
      isCode: true,
    });
  }

  return messages.map((m, i) => ({ ...m, id: message.id + `__${i}` }));
};
