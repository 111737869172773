import { ComponentProps, useEffect } from "react";

import { Button } from "@/components/elements/Button";
import { Emoji } from "@/components/elements/Emoji";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import { useMixpanel } from "@/monitoring/mixpanel";

export function FreeDataConsultationDialog(
  props: ComponentProps<typeof Modal>,
) {
  const mixpanel = useMixpanel();
  useEffect(() => {
    if (props.isOpen) {
      mixpanel.track("Holiday Data Consultation Offer Dialog Opened");
    }
  }, [props.isOpen, mixpanel]);
  return (
    <Modal {...props}>
      <ModalHeader>Exclusive Holiday Offer!</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <div className="space-y-5">
          <div>
            <h3 className="mb-2 font-semibold">
              <Emoji symbol="🎄" />
              <span className="mx-1">
                Holiday Special from Weld: Free Data Consultation!
              </span>
              <Emoji symbol="🎄" />
            </h3>
            <p>
              This December, enjoy a free 45-minute consultation with our expert
              partners to streamline your data and operations for the busy
              season.
            </p>
          </div>
          <div>
            <h3 className="mb-2 font-semibold">
              <Emoji symbol="📊" className="mr-1" />
              <span>What’s Included:</span>
            </h3>
            <ul className="list-inside list-disc">
              <li>Expert tips to optimize dashboards and reports.</li>
              <li>
                Tailored strategies to organize your data (including the new dbt
                integration).
              </li>
              <li>Guidance on setting up custom connectors.</li>
              <li>Practical advice to boost decision-making and workflows.</li>
            </ul>
          </div>
          <p>
            <Emoji symbol="💬" className="mr-1" />
            Book Your Free Consultation Now: Secure your spot today and
            kickstart smarter reporting!
          </p>
          <div>
            Happy holidays,
            <br />
            The Weld Team <Emoji symbol="🎁" className="mr-1" />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          as="a"
          href="https://cal.com/team/pyne/weld-x-pyne-office-hours"
          variant="solid"
          colorScheme="primary"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            mixpanel.track(
              "Holiday Data Consultation Offer - Book Meeting Clicked",
            );
          }}
        >
          Book Your Free Consultation Now!
        </Button>
      </ModalFooter>
    </Modal>
  );
}
