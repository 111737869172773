import classNames from "@/helpers/classNames";

const Container = ({
  children,
  className,
}: {
  children: JSX.Element | JSX.Element[];
  className?: string;
}) => (
  <div
    className={classNames(
      "mb-4 flex h-10 shrink-0 items-center justify-between sm:mb-6 md:mb-8",
      className,
    )}
  >
    {children}
  </div>
);

const PageHeader = { Container };
export default PageHeader;
