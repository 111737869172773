import { noop } from "lodash";
import { ComponentProps, Fragment, useEffect } from "react";
import { FormProvider } from "react-hook-form";

import {
  Survey,
  useGetPromptableProductNpsSurveyLazyQuery,
} from "@/apollo/types";
import { classed } from "@/components/classed.config";
import { Button, ButtonPrimitive } from "@/components/elements/Button";
import { CloseButtonIcon } from "@/components/elements/CloseButton";
import { Modal, ModalBody, ModalHeader } from "@/components/elements/Modal";
import Tooltip from "@/components/elements/Tooltip";
import { Heading } from "@/components/elements/Typography";
import { useCountdown } from "@/hooks/useCountdown";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useMountEffect } from "@/hooks/useMountEffect";
import { useTimeout } from "@/hooks/useTimeout";
import { useMixpanel } from "@/monitoring/mixpanel";
import { Portal, Transition } from "@headlessui/react";

import {
  NPSSurveyForm,
  NPSSurveyFormData,
  useNPSSurveyForm,
} from "./NPSSurveyForm";
import surveySenderProfileImage from "./chloe.png";
import { useNPSSurvey } from "./useNPSSurvey";

export function NPSSurveyDialogContainer() {
  const disclosure = useDisclosure();

  const [fetchSurvey, { data }] = useGetPromptableProductNpsSurveyLazyQuery({
    async onCompleted(data) {
      if (data.getPromptableProductNPSSurvey.survey != null) {
        disclosure.onOpen();
      }
    },
  });
  useTimeout(fetchSurvey, 60_000);

  const survey = data?.getPromptableProductNPSSurvey.survey;
  if (survey == null) {
    return null;
  }
  return <NPSSurveyDialog {...disclosure} survey={survey} />;
}

export function NPSSurveyDialog({
  survey,
  ...modalProps
}: ComponentProps<typeof Modal> & {
  survey: Pick<Survey, "id" | "name" | "question">;
}) {
  const { submitResponse, dismissSurvey, surveyResponse } =
    useNPSSurvey(survey);

  const mixpanel = useMixpanel();
  useEffect(() => {
    if (modalProps.isOpen) {
      mixpanel.track("Survey Dialog Opened", {
        surveyId: survey.id,
        surveyName: survey.name,
      });
    }
  }, [mixpanel, survey, modalProps.isOpen]);

  const isPromoterScore =
    surveyResponse?.score != null && surveyResponse?.score >= 9;
  if (isPromoterScore) {
    return <NPSPromoterThankYou {...modalProps} />;
  }

  return (
    <>
      <Transition.Root appear show={modalProps.isOpen} as={Fragment}>
        <Portal>
          <div className="pointer-events-none fixed bottom-0 left-0 z-50 flex w-full justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-150"
              enterFrom="opacity-0 translate-y-full scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-full scale-95"
            >
              <div className="pointer-events-auto mx-1 mb-1 w-full max-w-[38rem]">
                <ContentContainer className="flex gap-3">
                  <div className="hidden shrink-0 xs:block">
                    <Tooltip content="Hi, I'm Chloe! 👋">
                      <img
                        src={surveySenderProfileImage}
                        alt="Chloe from WELD"
                        className="h-7 w-7 rounded-full sm:h-10 sm:w-10"
                      />
                    </Tooltip>
                  </div>
                  <div className="grow">
                    {surveyResponse?.score != null ? (
                      <ThankYouMessage onClose={() => modalProps.onClose()} />
                    ) : (
                      <NPSSurveyView
                        question={survey.question}
                        onSubmit={async (values) => {
                          await submitResponse(values);
                        }}
                      />
                    )}
                  </div>
                  {surveyResponse == null && (
                    <div className="absolute right-0 top-0">
                      <ButtonPrimitive
                        onClick={() => {
                          dismissSurvey();
                          modalProps.onClose();
                        }}
                        className="flex rounded-bl-md rounded-tr-lg bg-black/10 px-2 py-0.5 text-xs text-white/90 hover:bg-black/20 hover:text-white"
                      >
                        <CloseButtonIcon className="mr-1 h-4 w-4" /> Ask me
                        later
                      </ButtonPrimitive>
                    </div>
                  )}
                </ContentContainer>
              </div>
            </Transition.Child>
          </div>
        </Portal>
      </Transition.Root>
    </>
  );
}

const ContentContainer = classed.div(
  "rounded-lg bg-gradient-to-r from-indigo-600 to-indigo-700 text-white px-3 sm:px-5 pt-5 pb-3 relative",
);

function NPSSurveyView(props: {
  question: string;
  onSubmit: (values: NPSSurveyFormData) => void;
}) {
  const formMethods = useNPSSurveyForm();
  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(props.onSubmit)}
        id="nps-survey-form"
      >
        <NPSSurveyForm question={props.question} />
      </form>
    </FormProvider>
  );
}

function NPSPromoterThankYou(props: ComponentProps<typeof Modal>) {
  const mixpanel = useMixpanel();
  return (
    <Modal {...props} onClose={noop}>
      <ModalHeader>Thanks for your positive feedback! 🌟</ModalHeader>
      <ModalBody className="pb-6">
        <p>
          As someone who appreciates our service, your endorsement means a lot.
          Please share your thoughts on G2 to help others discover the value of
          WELD.
        </p>
        <p className="mt-4">
          To show our appreciation, we're offering a{" "}
          <strong>$25 gift card!</strong> Just complete the G2 review, and your
          reward will be on its way.
        </p>
        <p className="mt-4">Thanks for your time and honesty! 🙌</p>
        <div className="mt-6 flex justify-between">
          <Button
            onClick={() => {
              mixpanel.track("NPS Promoter Review Later Clicked");
              props.onClose();
            }}
          >
            Maybe later
          </Button>
          <Button
            as="a"
            colorScheme="primary"
            variant="solid"
            href="https://www.g2.com/contributor/weld_rl-25_vs?secure%5Bpage_id%5D=weld_rl-25_vs&secure%5Brewards%5D=true&secure%5Btoken%5D=fc3ddb11e6cb8e3af868871724e05e233c4834825916137c42558afc3905d1bd"
            target="_blank"
          >
            Submit a G2 Review
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

function ThankYouMessage(props: { onClose: () => void }) {
  const [count, { startCountdown }] = useCountdown({
    countStart: 5,
    countStop: 0,
    intervalMs: 1_000,
  });

  useMountEffect(startCountdown);

  useEffect(() => {
    if (count === 0) {
      props.onClose();
    }
  }, [count, props]);

  return (
    <div className="flex gap-4 pb-2">
      <div>
        <Heading className="mb-2 text-sm font-normal sm:text-base">
          🌟 Thanks for your feedback!
        </Heading>
        <div className="flex items-center gap-4 pb-2">
          <div className="text-sm">
            We appreciate your input and will use it to enhance our service. If
            you have questions, please contact us via the in-app chat. 🙌
          </div>
        </div>
      </div>
      <div className="shrink-0">
        <ButtonPrimitive
          onClick={() => props.onClose()}
          className="whitespace-nowrap rounded border border-black/30 bg-white/20 px-2 py-1 text-sm shadow hover:bg-white/10"
        >
          Close ({count})
        </ButtonPrimitive>
      </div>
    </div>
  );
}
