import { LocationGenerics, getAccountContext } from "routes";

import { ConnectionsDocument } from "@/apollo/types";
import { ConnectionBreadcrumb } from "@/components/elements/breadcrumbs/ConnectionBreadcrumb";
import { ApolloClient } from "@apollo/client";
import { Route } from "@tanstack/react-location";

import { ConnectionsPage } from "./ConnectionsPage";
import EditConnectionPage from "./EditConnection";
import ViewConnectionPage from "./ViewConnection";
import { ViewConnectionLayout } from "./ViewConnectionLayout";

export const connectionsRoute = (
  client: ApolloClient<object>,
): Route<LocationGenerics> => {
  return {
    path: "connections",
    meta: {
      breadcrumb: () => "Connections",
    },
    children: [
      {
        path: ":connectionId",
        meta: {
          breadcrumb: ({ connectionId }) => (
            <ConnectionBreadcrumb connectionId={connectionId} />
          ),
        },
        element: <ViewConnectionLayout />,
        children: [
          {
            path: "configuration",
            element: <EditConnectionPage />,
            meta: {
              breadcrumb: () => "Configuration",
            },
          },
          {
            element: <ViewConnectionPage />,
          },
        ],
      },
      {
        element: <ConnectionsPage />,
        loader: async (match) => {
          return (
            await client.query({
              query: ConnectionsDocument,
              context: await getAccountContext(match.params.slug, client),
            })
          ).data;
        },
      },
    ],
  };
};
