import { atom, useAtom } from "jotai";
import { isEqual } from "lodash";
import { memo, useMemo } from "react";
import { CellProps, Column } from "react-table";

import { PreviewQueryResponse, QueryDependencyInput } from "@/apollo/types";
import { ReactComponent as EdIcon } from "@/assets/ed.svg";
import { Button } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import PreviewTable from "@/components/elements/PreviewTable";
import Tooltip from "@/components/elements/Tooltip";
import { QueryErrorMessages } from "@/components/modules/PreviewData/QueryErrorMessages";
import { useMixpanel } from "@/monitoring/mixpanel";

import { ModelType } from "../ModelEditorStore";
import { useAssistantSidebar } from "../model-generator/assistant";

export function useTableSchema(data: PreviewQueryResponse["response"]) {
  const tableSchema = useMemo(() => {
    //Map over keys to generate schema
    const [firstRow] = data;

    if (!firstRow) {
      return [];
    }

    const schema = Object.keys(firstRow).map((item) => ({
      Header: <span className="font-mono">{item}</span>,
      Cell: ({ row }: CellProps<any>) => {
        switch (row.original[item]) {
          case false:
            return (
              <span className="font-mono text-gray-800 dark:text-gray-200">
                false
              </span>
            );
          case true:
            return (
              <span className="font-mono text-gray-800 dark:text-gray-200">
                true
              </span>
            );
          case undefined:
          case null:
            return <span className="font-mono italic text-gray-400">NULL</span>;
          default:
            let result =
              typeof row.original[item] === "object"
                ? JSON.stringify(row.original[item])
                : row.original[item];

            return (
              <span className="font-mono text-gray-800 dark:text-gray-200">
                {result}
              </span>
            );
        }
      },
      accessor: item,
    }));

    return schema;
  }, [data]);
  return tableSchema;
}

export type PreviewType = {
  id: string;
  modelId: string;
  modelType: ModelType;
  state: "pending" | "loading" | "error" | "success";
  queryExecutionId?: string;
  rows?: any[];
  message?: string;
  dwSql?: string;
  totalRows?: number;
  startTimestamp: number;
  endTimestamp: number | null;
  displayName: string | null;
  historyDate?: Date;
  tabId?: string; //which tab was the preview called from
  weldSQL: string; //weld sql before it was compiled
  dependencies: QueryDependencyInput[]; //query dependencies at time of execution
  chatThreadId?: string; //chat thread id if this preview was generated from the AI Assistant
};

const previewsAtom = atom<PreviewType[]>([]);
export const usePreviewsAtom = () => useAtom(previewsAtom);

const previewOpenAtom = atom<string | null>(null);
export const usePreviewOpenAtom = () => useAtom(previewOpenAtom);

export const useCurrentPreview = () => {
  const [previewOpenId] = usePreviewOpenAtom();
  const [previews] = usePreviewsAtom();
  return useMemo(
    () => previews.find((p) => p.id === previewOpenId),
    [previews, previewOpenId],
  );
};

const previewCompiledSqlAtom = atom<boolean>(false);
export const usePreviewCompiledSqlAtom = () => useAtom(previewCompiledSqlAtom);

const Preview = memo(
  () => {
    const openPreview = useCurrentPreview();
    const previewRows = openPreview?.rows;

    const columndata: Column[] | undefined = useTableSchema(previewRows || []);

    if (
      openPreview &&
      ["loading", "error", "pending"].includes(openPreview.state)
    )
      return (
        <div className="flex animate-fadein items-start justify-center p-4 text-center text-sm text-gray-800 dark:text-gray-400">
          {(openPreview.state === "loading" ||
            openPreview.state === "pending") && (
            <p className="flex w-28 items-center space-x-2 text-left">
              <span>Loading</span> <LoadingSpinner />
            </p>
          )}
          {openPreview.state === "error" && (
            <ErrorMessageDisplay openPreview={openPreview} />
          )}
        </div>
      );

    if (!openPreview)
      return (
        <div className="p-4 text-center text-sm text-muted-foreground">
          Executed query results will appear here.
        </div>
      );

    if (!columndata?.length) {
      return (
        <div className="p-4 text-center text-sm">
          <QueryErrorMessages
            dataReferences={openPreview.dependencies}
            errorMsg="No rows returned from executed query."
          />
        </div>
      );
    }
    if (previewRows)
      return <PreviewTable columns={columndata} data={previewRows} />;

    return null;
  },
  (prev, next) => {
    return isEqual(prev, next);
  },
);

export default Preview;

const ErrorMessageDisplay = (props: { openPreview: PreviewType }) => {
  const mixpanel = useMixpanel();

  const { onOpen: onOpenAssistant } = useAssistantSidebar();

  const handleGetHelp = async () => {
    if (!props.openPreview.message) return;

    onOpenAssistant({
      threadId: props.openPreview.chatThreadId,
      input: props.openPreview.message,
      context: {
        id: props.openPreview.modelId,
        name: props.openPreview.displayName ?? "Executed Model",
        type: props.openPreview.modelType,
        sql: props.openPreview.weldSQL,
      },
    });

    mixpanel.track("AI Help Requested", {
      error_message: props.openPreview.message,
      model_id: props.openPreview.modelId,
    });
  };

  const showHelpButton = useMemo(() => {
    const internalErrors = [
      "Reference not found for:",
      "Bad gateway",
      "perhaps it does not exist in",
      `Syntax error: Unexpected "{"`,
    ];

    return !internalErrors.some((errorMsg) =>
      props.openPreview.message?.includes(errorMsg),
    );
  }, [props.openPreview.message]);

  return (
    <div className="space-y-1 py-2">
      <div>
        <QueryErrorMessages
          errorMsg={props.openPreview.message}
          dataReferences={props.openPreview.dependencies}
        />
      </div>
      {showHelpButton && (
        <Tooltip content={"Get help from Ed, you AI SQL assistant"}>
          <Button
            variant="outline"
            colorScheme="primary"
            size={"xs"}
            onClick={handleGetHelp}
            icon={<EdIcon />}
          >
            Help
          </Button>
        </Tooltip>
      )}
    </div>
  );
};
