import "./style.css";

import dayjs from "dayjs";
import { sanitize } from "dompurify";
import { ComponentProps, cloneElement } from "react";
import rehypeParse from "rehype-parse";
import rehypeReact from "rehype-react";
import { unified } from "unified";

import { Notification } from "@/apollo/types";
import IconWithBG from "@/components/elements/IconWithBG";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import { Heading } from "@/components/elements/Typography";
import { elements } from "@/components/elements/UnifiedElements";
import Center from "@/components/elements/layout/Center";
import { useNotificationItem } from "@/components/modules/notifications/useNotificationItem";
import { NotificationAction } from "@/features/notifications/components/NotificationAction";

const DATE_FORMAT = "MMM DD, HH:mm UTC";

export type MessageAction = {
  url?: string;
  onClick?: () => void;
  label: string;
};
export type MessageData = {
  action?: MessageAction;
  title: string;
  content: string;
  messageItems: {
    subtitle?: string;
    reassurance?: string;
    incidentReason?: string;
    incidentResolution?: string;
    incidentResolutionList?: string[];
    skipRenderList?: boolean;
  };
};

function isRichNotification(messageData: MessageData) {
  return !!messageData.messageItems;
}

function insertIf(
  condition: any,
  element: { name: string | undefined; value: string | undefined },
): { name: string; value: string }[] {
  if (element.value === undefined || element.name === undefined) {
    return [];
  }
  if (condition) {
    return [{ value: element.value, name: element.name }];
  }

  return [];
}

function getListItems(notification: Notification) {
  const detail = notification.detail;
  const list: { name: string; value: string }[] = [
    ...insertIf(
      detail.destinationIntegrationId ||
        (detail.sourceIntegrationId && detail.connectionLabel),
      {
        name: "Connector",
        value: `[${
          detail.destinationIntegrationId || detail.sourceIntegrationId
        }] ${detail.connectionLabel}`,
      },
    ),
    ...insertIf(detail.syncDestination, {
      name: "Sync destination",
      value: detail.syncDestination,
    }),
    ...insertIf(detail.destinationSchemaName, {
      name: "Destination schema",
      value: detail.destinationSchemaName,
    }),
    ...insertIf(detail.sourceStreamName, {
      name: detail.entityLevel || "Table",
      value: detail.sourceStreamName,
    }),
    ...insertIf(detail.errorMessage, {
      name: "Error message",
      value: detail.errorMessage,
    }),
    ...insertIf(detail.workflowName, {
      name: "Workflow",
      value: detail.workflowName,
    }),
    ...insertIf(detail.modelId, { name: "Model", value: detail.modelId }),
    ...insertIf(detail.target && detail.entityLevel, {
      name: detail.entityLevel,
      value: detail.target,
    }),
    ...insertIf(detail.databaseName, {
      name: "Database",
      value: detail.databaseName,
    }),
    ...insertIf(detail.dataWarehouseTitle, {
      name: "Data warehouse",
      value: detail.dataWarehouseTitle,
    }),
    ...(detail.customConnectorDetails
      ? [
          { name: "URL", value: detail.customConnectorDetails.url },
          {
            name: "Status code",
            value: detail.customConnectorDetails.statusCode,
          },
          {
            name: "Last state",
            value: detail.customConnectorDetails.lastState,
          },
        ]
      : []),
    {
      name: "Incident started",
      value: dayjs(notification.createdAt).format(DATE_FORMAT),
    },
  ];

  return list;
}

export function NotificationDetailModal({
  notification,
  ...modalProps
}: {
  notification: Notification;
} & ComponentProps<typeof Modal>) {
  const { title, icon, messageData } = useNotificationItem(notification);

  const getRichContent = (messageData: MessageData) => {
    const messageItems = messageData.messageItems || {};
    return (
      <div className="rich_notification_content space-y-4">
        {messageItems.subtitle ? (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(messageItems.subtitle),
            }}
          />
        ) : null}
        {messageItems.reassurance ? (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(messageItems.reassurance),
            }}
          />
        ) : null}
        {messageItems.skipRenderList ? null : (
          <ul className="list-inside list-disc">
            {getListItems(notification).map((x) => (
              <li key={x.name}>
                {x.name}: {x.value}
              </li>
            ))}
          </ul>
        )}
        {messageItems.incidentReason ? (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(messageItems.incidentReason),
            }}
          />
        ) : null}
        {messageItems.incidentResolution ? (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(messageItems.incidentResolution),
            }}
          />
        ) : null}
        {messageItems.incidentResolutionList ? (
          <div>
            <p className="mb-2">Tips for resolution:</p>
            <ul className="list-inside list-disc">
              {messageItems.incidentResolutionList?.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    );
  };

  const renderContent = () => {
    if (messageData == null) {
      return <>{notification.message}</>;
    }
    if (isRichNotification(messageData)) {
      return getRichContent(messageData);
    }

    const wrapHTML = (html: string) => {
      if (/^<(tbody|tr)/.test(html.trim())) {
        return `<table class='rounded bg-white'>${html}</table>`;
      }
      return html;
    };

    const html = unified()
      .use(rehypeParse, { fragment: true })
      .use(rehypeReact, elements)
      .processSync(wrapHTML(messageData.content)).result;
    return html;
  };

  return (
    <Modal size="md" isOpen={modalProps.isOpen} onClose={modalProps.onClose}>
      <ModalHeader className="pr-10">
        <div className="flex gap-3">
          {cloneElement<{ size: ComponentProps<typeof IconWithBG>["size"] }>(
            icon,
            {
              size: "sm",
            },
          )}
          <Center>
            <Heading className="text-sm">{title}</Heading>
          </Center>
        </div>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody className="pt-0 text-sm">{renderContent()}</ModalBody>
      <ModalFooter>
        <NotificationAction
          notification={notification}
          onClick={modalProps.onClose}
        />
      </ModalFooter>
    </Modal>
  );
}
