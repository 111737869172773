import "react-datepicker/dist/react-datepicker.css";

import { isEqual } from "lodash";
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { LocationGenerics } from "routes";

import {
  EltSyncDocument,
  EltSyncsDocument,
  OrchestrationSchedulerType,
  useConnectionsQuery,
  useEltSyncQuery,
  useUpdateEltSyncMutation,
} from "@/apollo/types";
import { ActionButton } from "@/components/elements/Button";
import DataBox from "@/components/elements/DataBox";
import MainPageLayout from "@/components/layouts/MainPageLayout";
import ConnectionSettings from "@/components/modules/ConnectionSettings";
import { SchedulerSelecter } from "@/components/modules/SchedulerSelecter";
import { TaskAction, TaskActionBar } from "@/components/modules/TaskActionBar";
import { AdvancedSyncScheduler } from "@/components/modules/WeldSyncScheduler";
import { useOrchestrationFeatureGuard } from "@/features/feature-guards";
import useChainedObject from "@/hooks/useChainedObject";
import useConnectionSettings from "@/hooks/useConnectionSettings";
import { useValidateCron } from "@/hooks/useCron";
import useLoadingManager from "@/hooks/useLoadingManager";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useToast } from "@/providers/ToastProvider";
import { useMatch, useNavigate } from "@tanstack/react-location";

import { SyncContext } from "./modules/SyncContext";
import TableConfig from "./modules/TableConfig";
import syncReducer, {
  EltSyncStateType,
  SourceStream,
  initSyncState,
  initialState,
} from "./modules/syncReducer";

export default function EditEltSyncPage() {
  return (
    <>
      <MainPageLayout narrow>
        <EditEltSync />
      </MainPageLayout>
      <TaskActionBar mode="normal" />
    </>
  );
}

function EditEltSync() {
  const [connectionSettingsValidated, setConnectionSettingsValidated] =
    useState(true);

  const mixpanel = useMixpanel();
  const navigate = useNavigate();
  const {
    params: { syncId },
  } = useMatch<LocationGenerics>();

  const { isEnabled: isOrchestrationEnabled } = useOrchestrationFeatureGuard();

  const initialSyncRef = useRef<EltSyncStateType>();

  const [state, dispatch] = useReducer(syncReducer, initialState);

  useEffect(() => {
    if (
      isOrchestrationEnabled === false &&
      state.schedulerType === OrchestrationSchedulerType.Global
    ) {
      dispatch({
        type: "update_scheduler_type",
        payload: {
          schedulerType: OrchestrationSchedulerType.Local,
        },
      });
    }
  }, [isOrchestrationEnabled, state.schedulerType]);

  const { loading: loadingSync } = useEltSyncQuery({
    variables: { eltSyncId: syncId },
    onCompleted(data) {
      const initialSyncState = initSyncState(data);

      initialSyncRef.current = JSON.parse(JSON.stringify(initialSyncState));
      dispatch({
        type: "initialize",
        payload: initialSyncState,
      });
    },
    onError(error) {
      toast("Something went wrong", error.message, "error");
    },
  });

  const { loading: connectionsLoading, data: { connections = [] } = {} } =
    useConnectionsQuery({
      fetchPolicy: "cache-and-network",
    });

  const { currentObject: currentSourceConnection } = useChainedObject(
    connections,
    "id",
    state.sourceId,
  );

  const hasChanged = useMemo(
    () =>
      initialSyncRef.current &&
      (
        Object.keys(
          initialSyncRef.current,
        ) as unknown as (keyof EltSyncStateType)[]
      ).some((key) => {
        if (key === "active") return false;
        return !isEqual(state[key], initialSyncRef.current?.[key]);
      }),
    [state],
  );

  const toast = useToast();

  const [updateEltSync, { loading: updating }] = useUpdateEltSyncMutation({
    onError(error) {
      toast(`Sync not updated`, error.message, "error");
    },
    onCompleted() {
      toast(`Sync updated`, `The sync was succesfully updated`, "success");

      navigate({ to: `..` });
    },
    refetchQueries: [
      {
        query: EltSyncsDocument,
      },
      {
        query: EltSyncDocument,
        variables: { eltSyncId: syncId },
      },
    ],
  });

  const cronValid = useValidateCron(state.scheduleKey);

  const validated = useMemo(() => {
    if (!connectionSettingsValidated) {
      return false;
    }
    if (!state.sourceId) {
      return false;
    }
    if (!Object.values(state.sourceStreams).length) {
      return false;
    }

    if (
      Object.values(state.sourceStreams).some((s) => s?.isIncrementalInvalid)
    ) {
      return false;
    }

    if (state.schedulerType === OrchestrationSchedulerType.Local) {
      if (!cronValid) {
        return false;
      }
    }

    if (
      state.schedulerType === OrchestrationSchedulerType.Global &&
      !state.orchestrationWorkflowId
    ) {
      return false;
    }

    return true;
  }, [
    connectionSettingsValidated,
    state.sourceId,
    state.sourceStreams,
    state.schedulerType,
    state.orchestrationWorkflowId,
    cronValid,
  ]);

  const connectionSettings = useConnectionSettings(
    currentSourceConnection?.id ?? "",
    "ELT",
  );

  const generateConfig = useCallback(() => {
    let config: any = {};
    if (connectionSettings.required) {
      config.sourceSettings = state.sourceSettings;
    }
    return JSON.stringify(config);
  }, [state.sourceSettings, connectionSettings.required]);

  const handleSubmit = useCallback(async () => {
    if (!validated) return;
    mixpanel.track("Data Source Configuration Update Submitted");
    updateEltSync({
      variables: {
        sourceStreams: Object.entries(state.sourceStreams)
          .filter((entry): entry is [string, SourceStream] => !!entry[1])
          .map(([name, stream]) => ({ name, ...stream }))
          .map(({ isIncrementalInvalid, ...stream }) => ({
            ...stream,
            substreams: Object.entries(stream.substreams).map(
              ([name, substream]) => ({
                name,
                ...substream,
              }),
            ),
          })),
        eltSyncId: syncId,
        syncInterval: state.scheduleKey ? state.scheduleKey : "",
        config: generateConfig(),
        schedulerType: state.schedulerType,
        orchestrationWorkflowId: state.orchestrationWorkflowId,
      },
    });
  }, [
    validated,
    updateEltSync,
    state.sourceStreams,
    state.scheduleKey,
    state.schedulerType,
    state.orchestrationWorkflowId,
    syncId,
    generateConfig,
    mixpanel,
  ]);

  const syncContext: [EltSyncStateType, any] = useMemo(
    () => [state, dispatch],
    [state, dispatch],
  );

  const loading = useMemo(
    () => loadingSync || connectionsLoading,
    [connectionsLoading, loadingSync],
  );

  const handleChangeCron = useCallback((cron?: string) => {
    dispatch({
      type: "change_schedule",
      payload: cron,
    });
  }, []);

  return useLoadingManager(
    {
      loading: loading,
      message: "Loading sync configuration",
    },
    () => (
      <div className="relative">
        <SyncContext.Provider value={syncContext}>
          <div className="space-y-4">
            <DataBox
              header="Schedule"
              subheader="Select scheduler when your sync will run."
            >
              <div className="space-y-4">
                {(isOrchestrationEnabled ||
                  state.schedulerType !== OrchestrationSchedulerType.Local) && (
                  <SchedulerSelecter
                    configuration={{
                      allowNewWorkflow: false,
                      allowNoSchedule: false,
                    }}
                    orchestrationScheduler={state.schedulerType}
                    orchestrationWorkflowId={state.orchestrationWorkflowId}
                    onUpdate={(config) => {
                      if (!config.orchestrationScheduler) return;
                      dispatch({
                        type: "update_scheduler_type",
                        payload: {
                          schedulerType: config.orchestrationScheduler,
                          orchestrationWorkflowId:
                            config.orchestrationWorkflowId,
                        },
                      });
                    }}
                    hideLabel
                  />
                )}
                {state.schedulerType === OrchestrationSchedulerType.Local && (
                  <AdvancedSyncScheduler
                    cron={state.scheduleKey}
                    onChange={handleChangeCron}
                  />
                )}
              </div>
            </DataBox>
            {(connectionSettings.required || connectionSettings.loading) && (
              <DataBox
                header="Connector"
                subheader="Configure the connector"
                loading={connectionSettings.loading}
              >
                <ConnectionSettings
                  connectionSettings={connectionSettings}
                  onChange={(payload) =>
                    dispatch({
                      type: "change_source_settings",
                      payload,
                    })
                  }
                  onChangeValid={(isValid) =>
                    setConnectionSettingsValidated(isValid)
                  }
                  initialValues={{
                    ...state.sourceSettings,
                    connectionId: currentSourceConnection?.id,
                  }}
                  refetchSettingsOnLoad={true}
                />
              </DataBox>
            )}
            {!loadingSync && currentSourceConnection && (
              <DataBox header="Tables" subheader="Modify your tables">
                <TableConfig
                  sourceId={state.sourceId}
                  sourceSettings={state.sourceSettings}
                  connection={currentSourceConnection}
                  mode="edit"
                  initialValues={state.sourceStreams}
                  onChange={(sourceStreams) =>
                    dispatch({
                      type: "set_source_streams",
                      payload: sourceStreams,
                    })
                  }
                  onCancel={() => navigate({ to: ".." })}
                  onError={(error) => {
                    toast(
                      `Could not fetch tables for "${currentSourceConnection?.label}"`,
                      error.message,
                      "error",
                    );
                  }}
                />
              </DataBox>
            )}
            <TaskAction>
              <ActionButton
                onClick={handleSubmit}
                disabled={!hasChanged || updating || !validated}
              >
                {updating ? "Updating" : "Update"} sync
              </ActionButton>
            </TaskAction>
          </div>
        </SyncContext.Provider>
      </div>
    ),
  );
}
