import { ComponentProps, useEffect } from "react";

import { Emoji } from "@/components/elements/Emoji";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import { UpgradeNowButton } from "@/components/modules/UpgradeNowButton";
import { useMixpanel } from "@/monitoring/mixpanel";

export function FreeMonthDiscountDialog(props: ComponentProps<typeof Modal>) {
  const mixpanel = useMixpanel();
  useEffect(() => {
    if (props.isOpen) {
      mixpanel.track("Holiday Discount Dialog Opened");
    }
  }, [props.isOpen, mixpanel]);
  return (
    <Modal centerOnScreen={false} {...props}>
      <ModalHeader>Exclusive Holiday Discount - Get 1 month free!</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <div className="space-y-5">
          <div>
            <h3 className="font-semibold">
              <Emoji symbol="🎄" />
              <span className="mx-1">Holiday Cheer from WELD!</span>
              <Emoji symbol="🎄" />
            </h3>
            <p>
              We have some exciting news to celebrate the season! This December,
              we’re thrilled to offer a special Christmas deal for everyone who
              signs up with WELD!
            </p>
          </div>
          <div>
            <h3 className="font-semibold">
              <Emoji symbol="🎁" className="mr-1" />
              <span>Sign Up for Any Paid Plan and Get a Free Month:</span>
            </h3>
            <p>
              New sign-ups to any paid plan in December will enjoy your first
              month free! If you’re currently on a trial, don’t worry—your
              billing will only start <em>after the free month ends.</em>
            </p>
          </div>
          <p>
            If you have any questions, feel free to reach via the in-app chat or
            email — we’re here to help set up your discount and make sure you
            have a smooth start! Happy holidays, and we look forward to
            welcoming you to WELD this season! <Emoji symbol="🎅" />
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <UpgradeNowButton
          source="holiday discount dialog"
          onClick={() =>
            mixpanel.track("Holiday Discount - Upgrade Now Clicked")
          }
        />
      </ModalFooter>
    </Modal>
  );
}
