import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useMemo, useState } from "react";
import { CellProps, Column } from "react-table";
import { useNavigateWithSlug } from "routes";

import {
  ConnectionsDocument,
  ConnectionsWithUsageSummaryDocument,
  ConnectionsWithUsageSummaryQuery,
  useRefreshDestinationMutation,
  useRemoveConnectionMutation,
} from "@/apollo/types";
import ConfirmDeleteModal from "@/components/elements/ConfirmDeleteModal";
import DefaultTable from "@/components/elements/DefaultTable";
import IntegrationCellTitle from "@/components/elements/IntegrationCellTitle";
import { List } from "@/components/elements/List";
import MenuItem from "@/components/elements/MenuItem";
import Tooltip from "@/components/elements/Tooltip";
import { TextMuted } from "@/components/elements/Typography";
import TableMenu from "@/components/modules/TableMenu";
import { useFilteredNotifications } from "@/features/notifications";
import { dateTimeFormat } from "@/helpers/dateFormat";
import useDeleteItem from "@/hooks/useDeleteItem";
import { Ability, useIntegrationsMap } from "@/integrations";
import { useToast } from "@/providers/ToastProvider";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

import { ConnectionsNotifications } from "./ConnectionNotifications";

dayjs.extend(relativeTime);

type ConnectionData = ConnectionsWithUsageSummaryQuery["connectors"][0];

const ConnectionsTable = (props: { data: ConnectionData[] }) => {
  const integrations = useIntegrationsMap();

  const columns = useMemo<Column<ConnectionData>[]>(
    () => [
      {
        Header: "Connector",
        Cell: ({
          value: integrationId,
        }: CellProps<ConnectionData, ConnectionData["integrationId"]>) => {
          return (
            <IntegrationCellTitle
              integrationId={integrationId}
              connectionLabel={integrations.get(integrationId)?.name}
              size="xs"
            />
          );
        },
        accessor: "integrationId",
        sortType: (rowA, rowB) => {
          const getSortValue = (row: ConnectionData) => {
            const val = integrations.get(row.integrationId)?.name;
            return val || row.integrationId;
          };
          return getSortValue(rowA.original).localeCompare(
            getSortValue(rowB.original),
          );
        },
      },
      {
        Header: "Name",
        Cell: ({
          row,
          value: label,
        }: CellProps<ConnectionData, ConnectionData["label"]>) => {
          if (label) return <div>{label}</div>;
          return (
            <Tooltip content="Name not set, using ID instead">
              <div className="text-gray-300">{row.original.integrationId}</div>
            </Tooltip>
          );
        },
        accessor: "label",
        sortType: (rowA, rowB) => {
          return rowA.original.label.localeCompare(rowB.original.label);
        },
      },
      {
        Header: "Created",
        Cell: ({
          value: createdAt,
        }: CellProps<ConnectionData, ConnectionData["label"]>) => {
          return (
            <Tooltip content={dayjs(createdAt).format(dateTimeFormat)}>
              <div>{dayjs(createdAt).fromNow()}</div>
            </Tooltip>
          );
        },
        accessor: "createdAt",
        sortType: (rowA, rowB) =>
          dayjs(rowA.original.createdAt).unix() -
          dayjs(rowB.original.createdAt).unix(),
      },
      {
        Header: "Updated",
        Cell: ({
          value: updatedAt,
        }: CellProps<ConnectionData, ConnectionData["updatedAt"]>) => {
          return (
            <Tooltip content={dayjs(updatedAt).format(dateTimeFormat)}>
              <div>{dayjs(updatedAt).fromNow()}</div>
            </Tooltip>
          );
        },
        accessor: "updatedAt",
        sortType: (rowA, rowB) =>
          dayjs(rowA.original.updatedAt).unix() -
          dayjs(rowB.original.updatedAt).unix(),
      },
      {
        accessor: "usageSummary",
        Header: "Sync Count",
        Cell: ({
          value: usageSummary,
        }: CellProps<ConnectionData, ConnectionData["usageSummary"]>) => {
          return (
            <Tooltip
              content={
                usageSummary.totalSyncs === 0 ? (
                  "This connector is not in use"
                ) : (
                  <List className="p-0">
                    {usageSummary.numEltSyncs > 0 && (
                      <List.Item className="p-0">
                        {usageSummary.numEltSyncs} Data Source{" "}
                        {usageSummary.numEltSyncs === 1 ? "sync" : "syncs"}
                      </List.Item>
                    )}
                    {usageSummary.numRevEltSyncs > 0 && (
                      <List.Item className="p-0">
                        {usageSummary.numRevEltSyncs} Reverse ETL{" "}
                        {usageSummary.numRevEltSyncs === 1 ? "sync" : "syncs"}
                      </List.Item>
                    )}
                  </List>
                )
              }
            >
              <div>
                {usageSummary.totalSyncs === 0 ? (
                  <TextMuted>-</TextMuted>
                ) : (
                  usageSummary.totalSyncs
                )}
              </div>
            </Tooltip>
          );
        },
        sortType: (rowA, rowB) =>
          rowA.original.usageSummary.totalSyncs -
          rowB.original.usageSummary.totalSyncs,
      },
      {
        id: "status",
        Header: "Status",
        Cell: ({ row }: CellProps<ConnectionData, ConnectionData["id"]>) => {
          return <Status connection={row.original} />;
        },
        width: 100,
        maxWidth: 100,
        disableSortBy: true,
      },
      {
        id: "actions",
        accessor: "id",
        Header: "",
        Cell: ({
          row,
          value: id,
        }: CellProps<ConnectionData, ConnectionData["id"]>) => {
          return (
            <div className="flex justify-end">
              <ConnectionMenu
                abilities={
                  integrations.get(row.original.integrationId)?.abilities || []
                }
                connectionId={id}
              />
            </div>
          );
        },
        disableSortBy: true,
      },
    ],
    [integrations],
  );

  const navigate = useNavigateWithSlug();
  const handleRowClick = (row: ConnectionData) =>
    navigate({ to: `/settings/connections/${row.id}` });

  return (
    <div className="flex flex-col gap-8">
      <ConnectionsNotifications connections={props.data} />
      <DefaultTable
        onRowClick={handleRowClick}
        columns={columns}
        data={props.data}
        isSortable
      />
    </div>
  );
};

const ConnectionMenu = ({
  connectionId,
  abilities,
}: {
  connectionId: string;
  abilities: Ability[];
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleDelete = useDeleteItem({
    title: "connection",
    variables: {
      connectionId,
    },
    mutation: useRemoveConnectionMutation,
    refetchQueries: [
      {
        query: ConnectionsDocument,
      },
      {
        query: ConnectionsWithUsageSummaryDocument,
      },
    ],
  });
  return (
    <>
      <ConfirmDeleteModal
        title="connection"
        onConfirm={() => {
          return handleDelete();
        }}
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
      />
      <TableMenu>
        <>
          {abilities.includes("ReverseEltDestinationConnection") && (
            <RefreshDestinationMenuItem connectionId={connectionId} />
          )}

          <MenuItem text="Delete" onClick={() => setShowConfirmModal(true)} />
        </>
      </TableMenu>
    </>
  );
};

const RefreshDestinationMenuItem = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const toast = useToast();

  const [refreshDestination] = useRefreshDestinationMutation({
    onError(error) {
      toast("Connection not refreshed", error.message, "error");
    },
    update: (cache) => {
      cache.modify({
        id: cache.identify({
          __typename: "Query",
        }),
        fields: {
          destinationProperties(_, { DELETE }) {
            return DELETE;
          },
          findAllAvailableOperations(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },

    onCompleted() {
      toast(
        "Connection refreshed",
        "Your connection has succesfully been refreshed.",
        "success",
      );
    },
    refetchQueries: [
      { query: ConnectionsDocument, variables: { type: "destination" } },
    ],
  });

  const onRefreshDestination = () => {
    refreshDestination({
      variables: { connectionId: connectionId },
    });
  };

  return (
    <MenuItem text="Refresh object types" onClick={onRefreshDestination} />
  );
};

const Status = (props: { connection: ConnectionData }) => {
  const { notifications } = useFilteredNotifications({
    connectionIds: [props.connection.id],
  });

  if (notifications.count) {
    return (
      <div className="flex">
        <ExclamationCircleIcon className="mr-1 h-5 w-5 shrink-0 text-red-600 dark:text-white" />
        <span>Suspended</span>
      </div>
    );
  }

  return (
    <div className="flex">
      <CheckCircleIcon className="mr-1 h-5 w-5 shrink-0 text-green-600 dark:text-white" />
      <span>Healthy</span>
    </div>
  );
};

export default ConnectionsTable;
