import { motion } from "framer-motion";
import React, { Fragment } from "react";

import { Tab } from "@headlessui/react";

import { classed } from "../../classed.config";

const TabButton = classed.button(
  "relative inline-flex items-center gap-1 text-sm focus-visible:outline-none",
  "cursor-pointer hover:text-blue-500 text-foreground dark:hover:text-blue-500",
  {
    variants: {
      selected: {
        true: "cursor-default text-blue-500",
      },
      size: {
        sm: "",
        md: "font-medium",
      },
    },
  },
);

type ExtractProps<T> = T extends React.ComponentType<infer P> ? P : T;

type AsProp<EType extends React.ElementType> = {
  as?: EType;
};

type WeldTabProps<EType extends React.ElementType> = ExtractProps<
  typeof Tab
> & {
  icon?: React.ReactNode;
  buttonSize?: "sm" | "md";
} & React.PropsWithChildren<AsProp<EType>> &
  React.ComponentPropsWithoutRef<EType>;

export function WeldTab<EType extends React.ElementType = "button">({
  icon,
  buttonSize = "md",
  as,
  ...props
}: WeldTabProps<EType>) {
  return (
    <Tab as={Fragment} {...props}>
      {({ selected }) => (
        <TabButton as={as as any} selected={selected} size={buttonSize ?? "md"}>
          {icon &&
            React.cloneElement(icon as any, {
              className: "shrink-0 w-4 h-4",
            })}
          {typeof props.children === "function"
            ? props.children({ selected })
            : props.children}

          {selected ? (
            <motion.div
              className="absolute -bottom-[2px] left-0 right-0 h-[2px] rounded-full bg-blue-500"
              layoutId="underline"
            />
          ) : null}
        </TabButton>
      )}
    </Tab>
  );
}
