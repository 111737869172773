import { FC, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";

import Toggle from "@/components/elements/Toggle";
import { InputError } from "@/components/primitives/InputError";
import { InputErrorIndicator } from "@/components/primitives/InputErrorIndicator";
import { useUnmountEffect } from "@/hooks/useUnmountEffect";
import { FormType } from "@/integrations/forms";

import FormFactory from "./FormFactory";
import { useMountEffect } from "@/hooks/useMountEffect";
import { isBoolean, isUndefined } from "lodash";

export const SubForm: FC<{
  form: Extract<FormType, { type: "sub_form" }>;
}> = ({ form }) => {
  const [visible, setVisible] = useState<boolean>(
    form.required || Boolean(form.value),
  );

  const { trigger, register, unregister, getValues, setValue } =
    useFormContext();
  const handleToggle = useCallback(
    async (value: boolean) => {
      setValue(form.name, value);
      setVisible(value);
      await trigger();
      if (!value) {
        form.fields.forEach((field) => {
          unregister(field.name);
        });
      }
    },
    [form, trigger, unregister, setValue],
  );

  useMountEffect(() => {
    if (!form.required && form.name) {
      const currentValue = getValues(form.name);
      if (isUndefined(currentValue)) {
        register(form.name, { value: Boolean(form.value) });
        return;
      }
      if (isBoolean(currentValue)) {
        setVisible(currentValue);
        return;
      }
    }
  });

  useUnmountEffect(() => {
    if (Array.isArray(form.fields)) {
      form.fields.forEach((field) => {
        unregister(field.name);
      });
    }
    if (!form.required) {
      unregister(form.name);
    }
  });

  if (!form.fields?.length) {
    return null;
  }

  return (
    <div>
      {(!form.required || form.value) && (
        <div className="flex items-center space-x-4 border-t py-8 dark:border-gray-700">
          <Toggle checked={visible} onChange={handleToggle} />
          <label className="text-sm text-gray-700 dark:text-gray-400">
            {form.label}
          </label>
        </div>
      )}
      {visible && form.fields?.map((f, i) => <FormFactory key={i} form={f} />)}
      {!!form.error && (
        <div className="flex flex-row items-center gap-1">
          <InputErrorIndicator />
          <InputError>{form.error}</InputError>
        </div>
      )}
    </div>
  );
};
