import { ComponentProps } from "react";

import IconWithBG from "@/components/elements/IconWithBG";
import { useDarkMode } from "@/providers/DarkModeProvider";

import { IntegrationLogo } from "./IntegrationLogo";
import { IntegrationId, getIntegrationLogo } from "./registry";

export function IntegrationLogoBox(
  props: Omit<Partial<ComponentProps<typeof IconWithBG>>, "id"> & {
    id: IntegrationId | (string & {}) | undefined;
  },
) {
  const { id, ...restProps } = props;
  const { isDarkModeEnabled } = useDarkMode();

  const getBackgroundColor = (integrationId: string | undefined) => {
    if (!integrationId || integrationId === "weld-sample-data") {
      return "black";
    }
    const config = getIntegrationLogo(integrationId);
    if (!config) {
      return isDarkModeEnabled ? "hsl(0, 0%, 24%)" : "hsl(0, 0%, 92%)";
    }
    if (isDarkModeEnabled) {
      return config.bg_dark;
    }
    return config.bg;
  };

  return (
    <IconWithBG
      {...restProps}
      icon={
        id === undefined ? (
          <IntegrationLogo id="weld" className="w-4/5" />
        ) : (
          <IntegrationLogo id={id} />
        )
      }
      color={getBackgroundColor(id)}
      size={props.size}
    />
  );
}
