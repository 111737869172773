import {
  LightAsync as SyntaxHighlighter,
  SyntaxHighlighterProps,
} from "react-syntax-highlighter";
import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";
import sql from "react-syntax-highlighter/dist/esm/languages/hljs/sql";
import defaultStyle from "react-syntax-highlighter/dist/esm/styles/hljs/vs";
import darkStyle from "react-syntax-highlighter/dist/esm/styles/hljs/vs2015";

import { useDarkMode } from "@/providers/DarkModeProvider";

SyntaxHighlighter.registerLanguage("sql", sql);
SyntaxHighlighter.registerLanguage("json", json);

export function CodeDisplay({
  code,
  language,
  showLineNumber = true,
  customStyle,
  ...restProps
}: Omit<SyntaxHighlighterProps, "children"> & { code: string }) {
  const { isDarkModeEnabled } = useDarkMode();
  return (
    <SyntaxHighlighter
      language={language}
      showLineNumbers={showLineNumber}
      style={isDarkModeEnabled ? darkStyle : defaultStyle}
      customStyle={{
        backgroundColor: isDarkModeEnabled ? "#141515" : "#F1F2F3",
        color: isDarkModeEnabled ? "#f8f8f8" : "#1d1f21",
        borderRadius: "0.75rem",
        padding: "1rem",
        fontSize: "0.75rem",
        ...customStyle,
      }}
      {...restProps}
    >
      {code}
    </SyntaxHighlighter>
  );
}

export function SQLCodeDisplay({ sql }: { sql: string }) {
  return <CodeDisplay code={sql} language="sql" />;
}

export function JSONCodeDisplay({ json }: { json: string }) {
  return <CodeDisplay code={json} language="json" />;
}
