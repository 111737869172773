import { twMerge } from "tailwind-merge";

import classNames from "@/helpers/classNames";
import { useDarkMode } from "@/providers/DarkModeProvider";

const colors = [
  ["#fb923c", "#ea580c"], // orange
  ["#fbbf24", "#d97706"], // amber
  ["#a3e635", "#65a30d"], // lime
  ["#34d399", "#059669"], // emerald
  ["#2dd4bf", "#0d9488"], // teal
  ["#22d3ee", "#0891b2"], // cyan
  ["#38bdf8", "#0284c7"], // sky
  ["#818cf8", "#4f46e5"], // indigo
  ["#a78bfa", "#7c3aed"], // violet
  ["#c084fc", "#9333ea"], // purple
  ["#e879f9", "#c026d3"], // fuchsia
  ["#f472b6", "#db2777"], // pink
  ["#fb7185", "#e11d48"], // rose
];

type UserAvatarProps = {
  firstName?: string | null;
  lastName?: string | null;
  size?: "lg";
  backgroundColor?: string;
} & React.ComponentProps<"span">;

export default function UserAvatar({
  firstName,
  lastName,
  size,
  className,
  backgroundColor,
  ...props
}: UserAvatarProps) {
  const fullName = `${firstName} ${lastName}`;
  const userAcronym =
    firstName && lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`
      : firstName?.charAt(0);

  const { isDarkModeEnabled } = useDarkMode();
  const colorPalette = isDarkModeEnabled
    ? colors.map((c) => c[1])
    : colors.map((c) => c[0]);
  return (
    <span
      className={twMerge(
        classNames(
          "flex flex-none items-center justify-center font-semibold uppercase text-white",
          size === "lg"
            ? "h-9 w-9 rounded-md text-xs"
            : "h-4 w-4 rounded-sm text-2xs",

          className,
        ),
      )}
      style={{
        backgroundColor:
          backgroundColor ?? randomColorFromString(fullName, colorPalette),
      }}
      aria-label={fullName}
      {...props}
    >
      {userAcronym || ":)"}
    </span>
  );
}

function randomColorFromString(str: string, palette: string[]) {
  if (str.length === 0) return "#9fa3ab";
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let index = ((hash % palette.length) + palette.length) % palette.length;
  return palette[index];
}
