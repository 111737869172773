import { atom, useAtom } from "jotai";
import { useCallback } from "react";

const dialogOpenState = atom(false);

export function useFreeDataConsultationDialog() {
  const [isOpen, setIsOpen] = useAtom(dialogOpenState);
  return {
    isOpen,
    open: useCallback(() => setIsOpen(true), [setIsOpen]),
    close: useCallback(() => setIsOpen(false), [setIsOpen]),
  };
}
