import { Suspense, useEffect } from "react";

import {
  ConnectionsWithUsageSummaryDocument,
  ConnectionsWithUsageSummaryQuery,
} from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import { EmptyState } from "@/components/elements/EmptyState";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useToast } from "@/providers/ToastProvider";
import { QueryRef, useBackgroundQuery, useReadQuery } from "@apollo/client";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useSearch } from "@tanstack/react-location";

import { SettingsPageLayout } from "../Settings/PageLayout";
import ConnectionsTable from "./ConnectionsTable";
import { NewConnectionSlideOver } from "./NewConnectionSlideOver";

export const ConnectionsPage = () => {
  const { error } = useSearch<{ Search: { error: string } }>();

  const toast = useToast();
  useEffect(() => {
    if (error) {
      toast("Something went wrong", error, "error");
    }
  }, [error, toast]);

  const addConnectionSlideOverDisclosure = useDisclosure();

  const [queryRef, { refetch }] =
    useBackgroundQuery<ConnectionsWithUsageSummaryQuery>(
      ConnectionsWithUsageSummaryDocument,
    );
  return (
    <>
      <SettingsPageLayout
        title="Connectors"
        action={
          <Button
            onClick={() => addConnectionSlideOverDisclosure.onOpen()}
            tabIndex={-1}
            icon={<PlusIcon className="w-4" />}
            variant="solid"
            colorScheme="primary"
          >
            New Connector
          </Button>
        }
      >
        <Suspense fallback={<LoadingSpinner />}>
          <ConnectionsContent
            queryRef={queryRef}
            onCreateConnection={() => addConnectionSlideOverDisclosure.onOpen()}
          />
        </Suspense>
      </SettingsPageLayout>
      <NewConnectionSlideOver
        size="lg"
        show={addConnectionSlideOverDisclosure.isOpen}
        onClose={() => {
          addConnectionSlideOverDisclosure.onClose();
        }}
        onConnectionAdded={() => {
          refetch();
        }}
        resetOnClose
        enableSelectExistingConnection={false}
      />
    </>
  );
};

function ConnectionsContent(props: {
  queryRef: QueryRef<ConnectionsWithUsageSummaryQuery>;
  onCreateConnection: () => void;
}) {
  const { data } = useReadQuery(props.queryRef);
  if (data.connectors.length === 0) {
    return (
      <EmptyState
        header="No Connections Found"
        description="A Connector enables you to connect to a data source or destination and start syncing data to and from WELD."
        action={
          <Button
            onClick={() => props.onCreateConnection()}
            icon={<PlusIcon />}
          >
            New Connector
          </Button>
        }
      />
    );
  }
  return <ConnectionsTable data={data.connectors} />;
}
