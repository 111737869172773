import { useCallback, useRef } from "react";

import {
  PlanLimitsStatus,
  ValidationErrorCode,
  usePlanLimitsStatusLazyQuery,
  useValidateConnectionAdditionLazyQuery,
} from "@/apollo/types";
import { ChangePlanDialog } from "@/features/billing";
import { useDisclosure } from "@/hooks/useDisclosure";

function useLimitReachedUpgradeDialog(options: {
  limitSelector: (status: PlanLimitsStatus) => boolean;
  title: string;
  message: string;
}) {
  const optionsRef = useRef(options);

  const [fetchPlanLimitsStatus, { loading }] = usePlanLimitsStatusLazyQuery();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const validateLimitReached = useCallback(
    async (onAllowCallback?: () => void, onDisallowCallback?: () => void) => {
      const resp = await fetchPlanLimitsStatus({
        fetchPolicy: "no-cache",
      });
      const status = resp.data?.getPlanLimitsStatus;
      if (!status) {
        onAllowCallback?.();
        return {
          isLimitReached: false,
        };
      }
      const isLimitReacted = optionsRef.current.limitSelector(status);
      if (isLimitReacted) {
        onOpen();
        onDisallowCallback?.();
      } else {
        onAllowCallback?.();
      }
      return {
        isLimitReached: isLimitReacted,
      };
    },
    [fetchPlanLimitsStatus, onOpen],
  );

  const limitReachedDialog = useCallback(() => {
    return (
      <ChangePlanDialog
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        title={optionsRef.current.title}
        message={optionsRef.current.message}
      />
    );
  }, [isOpen, onClose]);

  return {
    validateLimitReached,
    limitReachedDialog,
    validating: loading,
  };
}

export function useConnectorAdditionValidationUpgradeDialog() {
  const { isOpen, onClose, onOpen, data } = useDisclosure<{
    title: string;
    message: string;
  }>();

  const [validateConnectionAddition] = useValidateConnectionAdditionLazyQuery({
    fetchPolicy: "no-cache",
  });

  const validateFn = useCallback(
    async (
      integrationId: string,
      onAllowCallback?: () => void,
      onDisallowCallback?: () => void,
    ) => {
      const { data } = await validateConnectionAddition({
        variables: {
          integrationId,
        },
      });
      if (data == null) {
        onAllowCallback?.();
        return {
          isLimitReached: false,
          valid: true,
        } as const;
      }
      const validationResult = data.validateConnectionAddition;

      if (validationResult.valid === false) {
        let dialogTitle = "Upgrade to Access More Connectors";
        let dialogMessage =
          "You’ve reached the connector limit for your current plan. Upgrade your plan to add more connectors.";
        if (
          validationResult.errors.find(
            (x) => x.errorCode === ValidationErrorCode.InsufficientPlan,
          ) !== undefined
        ) {
          dialogTitle = "Connector Not Supported on Current Plan";
          dialogMessage = "Upgrade your plan to enable this connector.";
        }
        onOpen({
          title: dialogTitle,
          message: dialogMessage,
        });
        onDisallowCallback?.();
        return;
      }

      onAllowCallback?.();
      return;
    },
    [onOpen, validateConnectionAddition],
  );

  const validationDialog = useCallback(() => {
    return (
      <ChangePlanDialog
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        title={data?.title}
        message={data?.message}
      />
    );
  }, [isOpen, onClose, data]);

  return {
    validateConnectionAddition: validateFn,
    validationDialog,
  };
}

export function useUsersLimitReachedUpgradeDialog() {
  return useLimitReachedUpgradeDialog({
    limitSelector: (status) => status.isUsersLimitReached,
    title: "Upgrade to Add More Users",
    message:
      "You’ve reached the user limit for your current plan. Upgrade your plan to add more users.",
  });
}
