import { useCallback, useState } from "react";

export function useCopyToClipboard() {
  const [copiedValue, setCopiedValue] = useState<string | number | null>(null);

  const copy = useCallback(async (value: string | number) => {
    if (!navigator?.clipboard) {
      // eslint-disable-next-line no-console
      console.warn("Clipboard not supported");
      return false;
    }

    try {
      await navigator.clipboard.writeText(String(value));
      setCopiedValue(value);
      return true;
    } catch (error) {
      setCopiedValue(null);
      return false;
    }
  }, []);

  return [copiedValue, copy] as const;
}
