import { capitalize } from "lodash";
import { useCallback, useMemo, useState } from "react";

import {
  ConnectionsDocument,
  EltSyncsDocument,
  EltSyncsQuery,
  GetRawViewsDocument,
  GetTemplatesDocument,
  ListModelFoldersDocument,
  ListModelsDocument,
  useAttachEltSyncToTemplateMutation,
  useConnectionLazyQuery,
  useCreateEltSyncFromTemplateMutation,
} from "@/apollo/types";
import { Alert, AlertIcon } from "@/components/elements/Alert";
import { Button } from "@/components/elements/Button";
import { ButtonCard } from "@/components/elements/ButtonCard";
import { LoadingOverlay } from "@/components/elements/LoadingComponents";
import {
  SlideOver,
  SlideOverBody,
  SlideOverCloseButton,
  SlideOverFooter,
  SlideOverHeader,
} from "@/components/elements/SlideOver";
import Tooltip from "@/components/elements/Tooltip";
import ConnectionSettings, {
  SimplifiedFormProvider,
} from "@/components/modules/ConnectionSettings";
import {
  DefaultOptions,
  SyncScheduleRadioGroup,
  WeldSyncScheduler,
} from "@/components/modules/WeldSyncScheduler";
import { useDataSourceSlideOver } from "@/components/modules/new-data-source-slideover";
import { sourceStreamStateToSourceStreamInput } from "@/components/modules/new-data-source-slideover/contexts/DataSourceStepsContext";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import FieldLabel from "@/components/primitives/InputLabel";
import { useConnections } from "@/features/connectors";
import { SourceStreamsState } from "@/features/elt/reducers/sourceStreamsReducer";
import classNames from "@/helpers/classNames";
import cn from "@/helpers/classNames";
import useConnectionSettings from "@/hooks/useConnectionSettings";
import { useDisplayCron } from "@/hooks/useCron";
import { useEltSyncs } from "@/hooks/useSync";
import { IntegrationLogoBox, IntegrationType, useOAuth } from "@/integrations";
import TableConfig from "@/pages/EltSyncs/modules/TableConfig";
import SimplePopperPanel from "@/pages/Metrics/components/SimplePopperPanel";
import { useToast } from "@/providers/ToastProvider";
import { useApolloClient } from "@apollo/client";
import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Placement } from "@popperjs/core";

import { useOpenMetricsDescription } from "./CoreMetricsDescription";
import { useCurrentTemplate } from "./TemplatesProvider";
import { useTemplateConfig } from "./useTemplateConfig";

export const TemplateConnectorButton = (props: {
  integration: IntegrationType;
  size: "sm" | "lg";
  highlight?: boolean;
}) => {
  const [configureELTOpen, setConfigureELTOpen] = useState(false);

  const { connectTemplateSource: connectMarketingSource, loading: connecting } =
    useConnectTemplateSource(() => setConfigureELTOpen(true));

  const { connection, sync, loading } = useTemplateSource(props.integration.id);

  const { eltSyncs } = useEltSyncs();
  const existingSyncs = useMemo(() => {
    if (loading) return [];
    return eltSyncs.filter(
      (s) =>
        s.sourceIntegrationId === props.integration.id && s.id !== sync?.id,
    );
  }, [eltSyncs, loading, props.integration.id, sync?.id]);

  const [selectingExisting, setSelectingExisting] = useState(false);

  const connectionSetup = !!connection;
  const existingSyncsSetup = existingSyncs.length > 0;
  const isFullySetup = !!connection && !!sync;

  const { onOpen } = useViewDataSourceSlideOver();

  return (
    <>
      <div className="relative">
        <ButtonCard
          className={classNames(
            "group z-10 items-center rounded-sm transition-all",
            props.size === "sm"
              ? "p-0.5"
              : "w-64 justify-start rounded-sm p-5 text-left",
            isFullySetup ? "border-green-600/50 dark:border-green-600/75" : "",
          )}
          onClick={() => {
            if (loading || connecting) return;

            if (!connection) {
              connectMarketingSource(props.integration);
              return;
            }

            if (existingSyncs.length > 0 && !sync) {
              setSelectingExisting((p) => !p);
              return;
            }

            if (!sync) {
              setConfigureELTOpen(true);
              return;
            }

            onOpen({ syncId: sync.id });
          }}
          disabled={loading || connecting}
        >
          {props.size === "lg" && (
            <div className="flex w-full items-center justify-start gap-3">
              <IntegrationLogoBox id={props.integration.id} />
              <div className="flex min-w-0 flex-1 flex-col gap-1">
                <h3 className="w-full truncate font-medium leading-5 dark:text-white">
                  {props.integration.name}
                </h3>
                <div className="text-xs">
                  {isFullySetup
                    ? "Setup complete"
                    : existingSyncsSetup
                      ? "Add sync to metric"
                      : connectionSetup
                        ? "Click to configure sync"
                        : "Click to connect"}
                </div>
              </div>

              {connectionSetup && (
                <div
                  className={classNames(
                    isFullySetup ? "bg-green-600/80" : "bg-gray-300/80",
                    "flex h-5 w-5 items-center justify-center rounded-full text-white",
                  )}
                >
                  <CheckIcon className="h-3" />
                </div>
              )}
            </div>
          )}
          {props.size === "sm" && (
            <Tooltip
              content={
                isFullySetup
                  ? `Click to view ${props.integration.name} data source`
                  : existingSyncsSetup
                    ? "Add sync to metric"
                    : connectionSetup
                      ? `Click to configure ${props.integration.name} data source`
                      : `Click to connect to ${props.integration.name}`
              }
            >
              <div className="flex items-center">
                <div
                  className={cn(
                    "rounded-sm bg-white",
                    connectionSetup
                      ? ""
                      : "saturate-50 group-hover:saturate-100",
                  )}
                >
                  <IntegrationLogoBox id={props.integration.id} />
                </div>
                <div className="flex w-10 items-center justify-center">
                  {isFullySetup && (
                    <CheckCircleIcon className="h-5 text-green-500 dark:text-green-400" />
                  )}
                  {connectionSetup && !isFullySetup && (
                    <PlusIcon className="h-5 text-gray-700 dark:text-white" />
                  )}
                  {!connectionSetup && (
                    <PlusIcon className="h-5 text-gray-500 dark:text-white" />
                  )}
                </div>
              </div>
            </Tooltip>
          )}
        </ButtonCard>
        {/*Highligh BG: */}
        <div
          className={cn(
            "absolute inset-0 -ml-[2%] -mt-[2%] h-[110%] w-[104%] rounded-sm blur-sm transition-colors duration-500",
            props.highlight ? "bg-blue-500/25" : "bg-transparent",
          )}
        />
        <ExistingSelector
          isOpen={selectingExisting}
          onClose={() => setSelectingExisting(false)}
          existingSyncs={existingSyncs}
          isLarge={props.size === "lg"}
          integrationName={props.integration.name}
        />
      </div>
      {connection && (
        <ConfigureTemplateELTSlideOver
          integration={props.integration}
          connection={connection}
          isOpen={configureELTOpen}
          onClose={() => setConfigureELTOpen(false)}
        />
      )}
    </>
  );
};

const ExistingSelector = (props: {
  isOpen: boolean;
  onClose: () => void;
  placement?: Placement;
  existingSyncs: EltSyncsQuery["eltSyncs"];
  onSelectExisting?: (sync: EltSyncsQuery["eltSyncs"][0]) => void;
  integrationName?: string;
  isLarge?: boolean;
}) => {
  const toast = useToast();
  const openMetric = useOpenMetricsDescription();
  const [attachExistingEltToTemplate, { loading }] =
    useAttachEltSyncToTemplateMutation({
      refetchQueries: [
        { query: EltSyncsDocument },
        { query: GetTemplatesDocument },
        { query: ListModelsDocument },
        { query: ListModelFoldersDocument },
        { query: GetRawViewsDocument },
      ],
      onCompleted: () => {
        toast(
          "Data source added",
          `Successfully added ${props.integrationName} to core metric`,
          "success",
        );
        openMetric({ open: true });
        props.onClose();
      },
      onError: (e) => {
        toast("Error creating sync", e.message, "error");
      },
    });
  const [selectedSync, setSelectedSync] = useState<string | null>(null);

  const template = useCurrentTemplate();
  return (
    <SimplePopperPanel
      placement={props.isLarge ? "right-start" : "top-start"}
      isOpen={props.isOpen}
      onClose={() => {
        if (!loading) props.onClose();
      }}
      className="rounded-sm border bg-white shadow dark:bg-gray-800"
    >
      {props.existingSyncs.map((sync, i) => {
        return (
          <IntegrationSelectBtn
            key={sync.id}
            autoFocus={i === 0}
            sync={sync}
            integrationName={props.integrationName}
            loading={loading && selectedSync === sync.id}
            onClick={async () => {
              if (loading) return;
              setSelectedSync(sync.id);
              await attachExistingEltToTemplate({
                variables: {
                  input: {
                    eltSyncId: sync.id,
                    templateId: template.id,
                  },
                },
              });
              props.onClose();
            }}
          />
        );
      })}
    </SimplePopperPanel>
  );
};

const IntegrationSelectBtn = ({
  sync,
  integrationName,
  onClick,
  loading,
  autoFocus,
}: {
  sync: EltSyncsQuery["eltSyncs"][0];
  integrationName?: string;
  onClick?: () => void;
  loading?: boolean;
  autoFocus?: boolean;
}) => {
  const { cronDisplay } = useDisplayCron(sync.syncInterval || undefined);
  const template = useCurrentTemplate();

  return (
    <Tooltip
      content={`Add existing ${integrationName} data sync to ${template.name.toLowerCase()} metrics`}
    >
      <div>
        <button
          onClick={onClick}
          autoFocus={autoFocus}
          className={classNames(
            "relative flex items-center justify-center transition-all",
            "bg-white hover:bg-blue-50 dark:bg-gray-800 dark:hover:bg-blue-400/10",
            "rounded-md focus:border-gray-300 dark:border-gray-700 dark:hover:border-gray-500 dark:focus:border-gray-500",
            "focus:z-10 focus:ring-blue-600 focus:ring-offset-2",
            "overflow-hidden",
            "relative flex w-96 items-center justify-start space-x-4 p-4",
          )}
        >
          <div className="relative">
            {loading && <LoadingOverlay />}
            <IntegrationLogoBox id={sync.sourceIntegrationId} size="sm" />
          </div>
          <div className="text-left">
            <div className="font-medium">{sync.destinationSchemaName}</div>
            <div className="text-xs">
              {sync.sourceStreams.length} table
              {sync.sourceStreams.length > 1 && "s"} - updating {cronDisplay}
            </div>
          </div>
        </button>
      </div>
    </Tooltip>
  );
};

/**
 * Creates a function that can create the connector for a given integration with OAuth.
 */
const useConnectTemplateSource = (onFinish: () => void) => {
  const toast = useToast();
  const client = useApolloClient();

  const [fetchConnection] = useConnectionLazyQuery();
  const { handleOAuth, oAuthLoading } = useOAuth({
    onSuccess: async (connectionId) => {
      await client.refetchQueries({
        include: [ConnectionsDocument],
      });
      const { data } = await fetchConnection({
        variables: {
          id: connectionId,
        },
      });
      if (data?.connection == null) {
        toast("Connection not found", "Connection not found", "error");
        return;
      }

      onFinish();
    },
    onError: (error) => {
      toast("Connection not created", error.message, "error");
    },
  });

  const connectTemplateSource = useCallback(
    async (integration: IntegrationType) => {
      await handleOAuth({
        integrationId: integration.id,
        data: { label: integration.id },
      });
    },
    [handleOAuth],
  );
  return {
    connectTemplateSource,
    loading: oAuthLoading,
  };
};
const useTemplateSource = (integrationId: string) => {
  const { connections, loading: loadingConnections } = useConnections(
    "EltSourceConnection",
  );
  const { eltSyncs, loading: loadingSyncs } = useEltSyncs();

  const template = useCurrentTemplate();

  return useMemo(() => {
    const connection = connections.find(
      (c) => c.integrationId === integrationId,
    );

    const templateSync = template.templateEltSync.find(
      (tElt) => tElt.sourceIntegrationId === integrationId,
    );

    const sync = eltSyncs.find((s) => s.id === templateSync?.eltSyncId);

    return {
      connection,
      sync,
      loading: loadingConnections || loadingSyncs,
    };
  }, [
    connections,
    eltSyncs,
    integrationId,
    loadingConnections,
    loadingSyncs,
    template.templateEltSync,
  ]);
};

const ConfigureTemplateELTSlideOver = (props: {
  integration: IntegrationType;
  connection: NonNullable<ReturnType<typeof useTemplateSource>["connection"]>;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [sourceSettings, setSourceSettings] = useState<{ [key: string]: any }>(
    {},
  );
  const connectionSettings = useConnectionSettings(
    props.connection?.id ?? "",
    "ELT",
  );

  const [isValid, setIsValid] = useState(true);
  const [cron, setCron] = useState<string>(DefaultOptions["1d"].value);
  const [sourceStreamState, setSourceStreamState] =
    useState<SourceStreamsState>({});

  const template = useCurrentTemplate();
  const toast = useToast();

  const [createEltFromTemplate, { loading }] =
    useCreateEltSyncFromTemplateMutation({
      refetchQueries: [
        { query: EltSyncsDocument },
        { query: GetTemplatesDocument },
        { query: ListModelsDocument },
        { query: ListModelFoldersDocument },
        { query: GetRawViewsDocument },
      ],
      onCompleted: () => {
        toast("Sync created", "Sync created", "success");
        props.onClose();
      },
      onError: (e) => {
        toast("Error creating sync", e.message, "error");
      },
    });

  const { onOpen } = useDataSourceSlideOver();
  const handleOpenAdvancedSetup = () => {
    onOpen({
      connectionId: props.connection.id,
      integrationId: props.integration.id,
    });
    props.onClose();
  };

  return (
    <SlideOver
      show={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
      bgOverlay
    >
      <SlideOverCloseButton />
      <SlideOverHeader className="flex items-center space-x-2">
        <IntegrationLogoBox id={props.integration.id} size="md" />
        <div>
          <div className="flex items-center space-x-1">
            <p className="text-xs">
              {capitalize(template.name)} metrics configuration
            </p>
          </div>
          <p>{props.integration.name}</p>
        </div>
      </SlideOverHeader>
      <SlideOverBody>
        <div className="flex h-full flex-col space-y-8">
          {loading && <LoadingOverlay className="z-20" />}
          <SimplifiedFormProvider>
            <ConnectionSettings
              connectionSettings={connectionSettings}
              onChange={(settings) => {
                setSourceSettings((p) => {
                  return {
                    ...p,
                    ...settings,
                  };
                });
              }}
              onChangeValid={(isValid) => {
                setIsValid(isValid);
              }}
              initialValues={{
                ...sourceSettings,
                connectionId: props.connection?.id,
              }}
            />
            <div>
              <FieldLabel className="flex items-center space-x-1">
                Data tables
              </FieldLabel>

              <TemplateTableSelecter
                integrationId={props.integration.id}
                sourceId={props.connection.id}
                settings={sourceSettings}
                connection={props.connection}
                onChange={(sourceStreams) => {
                  setSourceStreamState(sourceStreams);
                }}
                initialValues={{}}
              />
            </div>
            <div>
              <FieldLabel>Sync interval</FieldLabel>
              <WeldSyncScheduler cron={cron} onChange={(cron) => setCron(cron)}>
                {({ value, mode, showTimeOffsetInput }) => (
                  <>
                    <SyncScheduleRadioGroup className="grid grid-cols-2 gap-x-4 gap-y-3" />
                  </>
                )}
              </WeldSyncScheduler>
            </div>
          </SimplifiedFormProvider>
          <div className="flex flex-grow items-end">
            <Alert>
              <AlertIcon />
              <div className="text-xs">
                {props.integration.name} for{" "}
                <b>{capitalize(template.name)} Metrics</b> will automatically
                use a pre-configured setup. You can modify the settings later,
                or
                <button
                  onClick={handleOpenAdvancedSetup}
                  className="ml-1 inline-flex items-center underline"
                >
                  open advanced setup
                </button>
              </div>
            </Alert>
          </div>
        </div>
      </SlideOverBody>
      <SlideOverFooter>
        <Button
          onClick={async () => {
            props.onClose();
          }}
          variant="outline"
        >
          Cancel
        </Button>

        <Button
          colorScheme="primary"
          variant="solid"
          disabled={!isValid}
          isLoading={loading}
          onClick={() => {
            if (loading) return;
            if (!isValid) return;

            createEltFromTemplate({
              variables: {
                input: {
                  sourceConnectionId: props.connection.id,
                  sourceIntegrationId: props.connection.integrationId,
                  templateId: template.id,
                  config: JSON.stringify({
                    sourceSettings,
                  }),
                  syncInterval: cron,
                  sourceStreams:
                    sourceStreamStateToSourceStreamInput(sourceStreamState),
                },
              },
            });
          }}
        >
          Start syncing data
        </Button>
      </SlideOverFooter>
    </SlideOver>
  );
};

const TemplateTableSelecter = (props: {
  integrationId: string;
  sourceId: string;
  settings: Record<string, any>;
  connection: any;
  initialValues?: SourceStreamsState | undefined;
  onChange: (sourceStreams: SourceStreamsState) => void;
}) => {
  const config = useTemplateIntegrationConfig(props.integrationId);
  const toast = useToast();

  const requiredStreams = useMemo(() => {
    return config?.sourceStreams.map((stream) => stream.name) ?? [];
  }, [config?.sourceStreams]);

  return (
    <div className="max-h-80 overflow-auto rounded border px-2 pb-2">
      {props.connection && (
        <TableConfig
          sourceId={props.sourceId}
          sourceSettings={props.settings}
          connection={props.connection}
          mode="create"
          initialValues={props.initialValues}
          onChange={props.onChange}
          onError={(error) => {
            toast(
              `Could not fetch tables for "${props.connection?.label}"`,
              error.message,
              "error",
            );
          }}
          requiredStreams={requiredStreams}
          size="small"
        />
      )}
    </div>
  );
};

const useTemplateIntegrationConfig = (integrationId: string) => {
  const { config } = useTemplateConfig();
  return useMemo(() => {
    if (!config) return null;

    return config?.integrations.find(
      (tIntegration) => tIntegration.sourceIntegationId === integrationId,
    )?.config;
  }, [config, integrationId]);
};
