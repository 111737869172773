import { useMemo } from "react";

import { DependencyType, QueryDependencyInput } from "@/apollo/types";
import Tooltip from "@/components/elements/Tooltip";
import { IntegrationLogo } from "@/integrations";
import EltSyncStatusLED from "@/pages/ModelTool/components/EltSyncStatusLED";
import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import { useRawViews } from "@/pages/ModelTool/useRawViews";

import { useViewDataSourceSlideOver } from "../view-data-source-slideover";

/*
 * Display different usefull error messages for when a query failed.
 */
export const QueryErrorMessages = (props: {
  errorMsg?: string; //Original error message as returned from DW / BE
  dataReferences: QueryDependencyInput[]; //Any references used in the query
}) => {
  const noRowsFound = [
    "No rows returned from executed query.",
    "Result does not contain any rows",
  ].includes(props.errorMsg || "");

  const isReferenceError = useMemo(
    () =>
      [
        `Syntax error: Unexpected "{"`,
        `Reference not found for: `,
        `or perhaps it does not exist`,
      ].some((errorMsg) => (props.errorMsg || "").includes(errorMsg)),
    [props.errorMsg],
  );

  return (
    <>
      <div className="flex grow-0 flex-col items-center space-y-8">
        {noRowsFound && (
          <NoRowsErrorInfo
            dataReferences={props.dataReferences}
            errorMsg={props.errorMsg}
          />
        )}
        {isReferenceError && (
          <ReferenceError
            errorMsg={props.errorMsg}
            dataReferences={props.dataReferences}
          />
        )}
        {!noRowsFound && !isReferenceError && (
          <div className="w-full max-w-xl px-6 py-2">
            <div className="mb-4 text-lg font-semibold text-muted-foreground">
              {props.errorMsg || "Unknown error."}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const ReferenceError = (props: {
  errorMsg?: string;
  dataReferences: QueryDependencyInput[];
}) => {
  return (
    <>
      <div className="w-full max-w-xl px-6 py-2">
        <div className="mb-4 text-lg font-semibold text-muted-foreground">
          {props.errorMsg || "Unknown error."}
        </div>
        <div className="my-6 flex justify-center text-left">
          <div className="w-fit space-y-2 rounded-md border border-yellow-400/40 bg-yellow-50 px-6 py-4 dark:border-yellow-600/40 dark:bg-yellow-600/5">
            <div className="mb-2 font-semibold">Error with data reference</div>
            <div>
              It looks like there might be an issue with the data reference.
              Read more about how to{" "}
              <a
                className="underline"
                href={`https://weld.app/docs/account/transform/data-models#reference-models`}
                target="_blank"
                rel="noreferrer"
              >
                reference data in WELD
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NoRowsErrorInfo = (props: {
  dataReferences: QueryDependencyInput[];
  errorMsg?: string;
}) => {
  const incompleteViews = useIncompleteRawViews(props.dataReferences);
  const { onOpen: viewSync } = useViewDataSourceSlideOver();
  if (!incompleteViews.length) {
    return (
      <div className="w-full max-w-xl px-6 py-2">
        <div className="mb-4 text-lg font-semibold">
          {props.errorMsg || "Unknown Error"}
        </div>
        <div className="mb-2 text-muted-foreground">
          Please check the following:
        </div>
        <div className="mx-auto w-fit text-left">
          <ul className="list-inside list-decimal">
            <li>
              Verify that your <code className="font-medium">WHERE</code> clause
              correctly filters the intended data.
            </li>
            <li>
              Confirm that all referenced tables contain the necessary data.
            </li>
          </ul>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="w-full max-w-2xl px-6 py-2">
        <div className="mb-2 text-lg font-semibold">
          Data Synchronization in Progress
        </div>
        <div className="text-muted-foreground">
          Preview is currently unavailable due to pending data synchronization.
          <br />
          The following data sources have not completed their initial sync:
        </div>
        <div className="my-6 flex justify-center">
          <ul className="w-fit space-y-2 rounded-md border border-yellow-400/40 bg-yellow-50 px-6 py-4 dark:border-yellow-600/40 dark:bg-yellow-600/5">
            {incompleteViews.map((view) => {
              return (
                <Tooltip
                  content={"Click to view data sync status"}
                  key={view.viewId}
                >
                  <li
                    role="button"
                    className="flex items-center gap-2 underline underline-offset-4"
                    onClick={() => {
                      if (!view.syncId) {
                        return;
                      }
                      viewSync({ syncId: view.syncId });
                    }}
                  >
                    {view.integrationId && (
                      <IntegrationLogo
                        id={view.integrationId || ""}
                        className="h-4 w-4"
                      />
                    )}
                    <div>{`${view.weldTag}`}</div>
                    <div>
                      <EltSyncStatusLED eltSyncId={view.syncId ?? ""} />
                    </div>
                  </li>
                </Tooltip>
              );
            })}
          </ul>
        </div>
        <div className="text-muted-foreground">
          Initial synchronization for certain integrations may take up to 24
          hours to complete.
        </div>
      </div>
    </>
  );
};

const useIncompleteRawViews = (queryDependency: QueryDependencyInput[]) => {
  const { rawViews } = useRawViews();
  const { models } = useListModels();

  return useMemo(() => {
    const rawViewIds = queryDependency.flatMap((dependency) => {
      switch (dependency.type) {
        case DependencyType.ModelView:
        case DependencyType.MaterializedTable:
          return getRawViewIds(dependency.dwItemId, models, []);
        case DependencyType.RawView:
          return [dependency.dwItemId];
        default:
          return [];
      }
    });

    // Get the view objects
    const incompleteViews = rawViews
      .filter((rawView) => rawViewIds.includes(rawView.viewId))
      .filter((view) => !view.firstSyncCompleted);

    return incompleteViews;
  }, [rawViews, queryDependency, models]);
};

/*
 * This function is used to get the raw view ids a model is depending on.
 * It traverses back through the dependencies of the model to find all the raw views.
 */
const getRawViewIds = (
  modelId: string,
  allModels: ReturnType<typeof useListModels>["models"],
  rawViewIds: string[],
) => {
  const model = allModels.find((m) => m.id === modelId);
  if (!model) return rawViewIds;

  const nextRawViews: string[] = (
    model.publishedQuery?.dependencies || []
  ).flatMap((dependency) => {
    switch (dependency.type) {
      case DependencyType.RawView:
        return [dependency.dwItemId];
      case DependencyType.MaterializedTable:
      case DependencyType.ModelView:
        return getRawViewIds(dependency.dwItemId, allModels, []);
      default:
        return [];
    }
  });

  return [...nextRawViews, ...rawViewIds];
};
