import { ReactNode } from "react";

export function EmptyState(props: {
  header: string;
  description?: string;
  action?: ReactNode;
}) {
  return (
    <div className="flex min-h-80 items-center justify-center p-12">
      <div className="flex flex-col items-center gap-6">
        <p className="font-medium">{props.header}</p>
        {props.description && (
          <p className="max-w-md text-center text-muted-foreground">
            {props.description}
          </p>
        )}
        <div className="flex gap-4">{props.action}</div>
      </div>
    </div>
  );
}
