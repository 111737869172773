import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ComponentProps, Suspense, useEffect } from "react";

import { Alert, AlertIcon, AlertTitle } from "@/components/elements/Alert";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import { WELD_EXISTING_CUSTOMER_CALL_URL } from "@/constants/external-urls/hubspot";
import { WELD_PRICING_URL } from "@/constants/external-urls/weld-website";
import { useMixpanel } from "@/monitoring/mixpanel";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/16/solid";
import { ErrorBoundary } from "@sentry/react";

import { BestFitLoader, BestFitPlanProjection } from "./BestFitPlanProjection";

dayjs.extend(utc);

const deadline = dayjs.utc("2024-12-01");

export function EssentialPlanDiscontinuationDialog(
  props: ComponentProps<typeof Modal>,
) {
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (props.isOpen) {
      mixpanel.track("Essential Plan Discontinuation Dialog - Opened");
    }
  }, [props.isOpen, mixpanel]);

  const daysLeft = Math.max(
    0,
    Math.ceil(dayjs.utc(deadline).diff(dayjs.utc(), "days", true)),
  );

  return (
    <Modal {...props} centerOnScreen={false} size="lg">
      <ModalHeader>
        Only{" "}
        <strong>
          {daysLeft} {daysLeft === 1 ? "Day" : "Days"}
        </strong>{" "}
        Until Essential Plan Discontinuation
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody className="pb-5">
        <div className="space-y-8">
          <div>
            <Alert status="warning">
              <AlertIcon />
              <div className="">
                <AlertTitle>
                  Urgent: Transition Required by December 1st, 2024!
                </AlertTitle>
                <div>
                  If you do not transition to a new plan by this date, we will
                  automatically upgrade you to the plan that best fits your
                  current usage based on the connectors you have set up. See
                  below for our recommended plan.
                </div>
              </div>
            </Alert>
          </div>
          <p>
            Thank you for being a valued Weld customer! We’re writing to remind
            you that your current Essential Plan will be discontinued on{" "}
            <strong>December 1st, 2024</strong>. To ensure uninterrupted service
            and continued support, please transition to one of our new plans,
            which you can{" "}
            <a
              href={WELD_PRICING_URL}
              className="inline-flex items-center text-blue-600 underline"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                mixpanel.track(
                  "Essential Plan Discontinuation Dialog - Pricing Page Clicked",
                );
              }}
            >
              explore on our website{" "}
              <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
            </a>
            .
          </p>
          <ErrorBoundary>
            <Suspense fallback={<BestFitLoader />}>
              <BestFitPlanProjection />
            </Suspense>
          </ErrorBoundary>
          <p>
            We’re here to help with any pricing discussions or negotiations you
            might need.{" "}
            <a
              href={WELD_EXISTING_CUSTOMER_CALL_URL}
              className="inline-flex items-center text-blue-600 underline"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                mixpanel.track(
                  "Essential Plan Discontinuation Dialog - Book a Call Clicked",
                );
              }}
            >
              Book a call with our sales team{" "}
              <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
            </a>{" "}
            or reply to our previous emails, and we’ll assist you directly.
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
}
