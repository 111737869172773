import "diff2html/bundles/css/diff2html.min.css";

import "./diff-viewer.css";
import "./monaco-style-overwrites.css";

import { atom, useAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { debounce } from "lodash";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";
import { useAutoHideRemoteUserSelection } from "@/features/model-tool";
import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import { TrackRecentModels_Detached } from "@/hooks/useRecentModels";
import { useDarkMode } from "@/providers/DarkModeProvider";
import Editor from "@monaco-editor/react";

import {
  useGetEditorTextState,
  useModelEditorDispatch,
  useModelEditorState,
  useModelEditorTextState,
} from "../ModelEditorStore";
import { EDITOR_TAB_SIZE } from "../constants/editor";
import { useFireMonaco } from "../hooks/useFireMonaco";
import { useKeyEventHandler } from "../hooks/useKeyboardShortcuts";
import { useListPublishedModels } from "../hooks/useListModels";
import { useExecuteSql } from "./ActionButtons";
import AutoSaveNotification from "./AutoSaveNotification";
import useCompletionItems from "./completion-suggestions/useCompletionItems";
import { useHighlightWeldTags } from "./monaco/useHighlighWeldTags";
import useModelMarkers from "./monaco/useModelMarkers";
import { useMonacoActions } from "./monaco/useMonacoActions";
import { useWeldTagClickHandler } from "./monaco/useWeldTagClickHandler";
import { useWeldTagHoverDialog } from "./monaco/useWeldTagHoverDialog";
import { useCurrentModelDraft } from "./useModelDraft";

const editorProps: monaco.editor.IEditorOptions &
  monaco.editor.IDiffEditorBaseOptions = {
  minimap: {
    enabled: false,
  },
  automaticLayout: true,
  scrollbar: {
    verticalScrollbarSize: 0,
  },
  hideCursorInOverviewRuler: true,
  renderLineHighlight: "none",
  scrollBeyondLastLine: false,
  padding: { top: 20 },
  renderOverviewRuler: false,
  enableSplitViewResizing: false,
  renderIndicators: false,
  fixedOverflowWidgets: true,
};

const selectedTextAtom = atom<string>("");
export const useSelectedText = () => useAtom(selectedTextAtom);

export const useGetSelectedText = () => {
  return useAtomCallback(
    useCallback((get) => {
      const value = get(selectedTextAtom);
      return value;
    }, []),
  );
};

const monacoInstanceAtom = atom<monaco.editor.IStandaloneCodeEditor | null>(
  null,
);
export const useMonacoInstance = () => useAtom(monacoInstanceAtom);

export default function SQLEditor(props: {}) {
  const { isDarkModeEnabled } = useDarkMode();
  const state = useModelEditorState();
  const dispatch = useModelEditorDispatch();

  const getTextState = useGetEditorTextState();

  const [, setMonacoInstance] = useMonacoInstance();

  const { updateCurrentDraftModel } = useCurrentModelDraft();

  const updateDraftDebounced = useDebouncedCallback(
    updateCurrentDraftModel,
    500,
  );

  const handleChangeSqlQuery = (query: string) => {
    dispatch({ type: "change_sql_query", payload: query });
    if (state.currentModelType === "draft") {
      updateDraftDebounced(query);
    }
  };

  useAutoHideRemoteUserSelection(state.currentModelId);

  const { connect: connectToFirebase, isConnected } = useFireMonaco({
    onReady: (text, setText) => {
      const editorTextState = getTextState();
      if (text === "" && editorTextState.defaultSql) {
        setText(editorTextState.defaultSql);
      }
      handleChangeSqlQuery(text);
    },
    onSynced: (text) => {
      handleChangeSqlQuery(text);
    },
  });

  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

  const [editorLoaded, setEditorLoaded] = useState(false);

  const handleKeyEventRef = useLatestValueRef(useKeyEventHandler());

  const handleMountEditor = useCallback(
    (editor: monaco.editor.IStandaloneCodeEditor) => {
      // Use line feed (\n) as the end of line character.
      // This is important for collaborative editing to prevent sync-issues between different browsers & platforms.
      editor.getModel()?.setEOL(monaco.editor.EndOfLineSequence.LF);
      // Set editor tab size so that it aligns with our formatter config.
      editor.getModel()?.updateOptions({ tabSize: EDITOR_TAB_SIZE });
      editor.focus();
      editorRef.current = editor;
      setMonacoInstance(editor);
      setEditorLoaded(true);

      editor.onKeyDown((e) => {
        handleKeyEventRef.current(e.browserEvent);
      });
      if (state.currentModelType === "model") {
        connectToFirebase(editor, state.currentModelId ?? "");
      }
    },
    [
      setMonacoInstance,
      state.currentModelType,
      state.currentModelId,
      connectToFirebase,
      handleKeyEventRef,
    ],
  );

  useEffect(
    () => () => {
      setEditorLoaded(false);
      setMonacoInstance(null);
    },
    [setMonacoInstance],
  );

  const [, setSelectedText] = useSelectedText();
  const updateSelectedText = useCallback(() => {
    const editor = editorRef.current;
    if (editor) {
      const selection = editor.getSelection();
      if (!selection) return;
      const text = editor.getModel()?.getValueInRange({
        startLineNumber: selection.startLineNumber,
        startColumn: selection.startColumn,
        endLineNumber: selection.endLineNumber,
        endColumn: selection.endColumn,
      });
      setSelectedText(text ?? "");
      return;
    }

    setSelectedText("");
  }, [setSelectedText]);

  useEffect(() => {
    if (!editorLoaded) return;

    const debouncedUpdateSelectedText = debounce(updateSelectedText, 100);
    const disposable = editorRef.current?.onDidChangeCursorSelection(() => {
      debouncedUpdateSelectedText();
    });
    return () => {
      disposable?.dispose();
      setSelectedText("");
    };
  }, [editorLoaded, setSelectedText, updateSelectedText]);

  const handleExecute = useExecuteSql();
  const { publishedModels } = useListPublishedModels();
  const openModelInTab = useOpenModelInTab();

  useWeldTagClickHandler(editorRef.current, (event, { weldTag }) => {
    if (event.metaKey) {
      if (weldTag.startsWith("raw.")) {
        return;
      }
      const model = publishedModels.find((pm) => {
        return (
          pm.dwSync?.weldTag === weldTag || pm.dwTable?.weldTag === weldTag
        );
      });
      if (model) {
        openModelInTab({ modelId: model.id });
      }
    }
  });

  useMonacoActions(editorRef.current, [
    {
      id: "execute-query",
      label: "Execute Query",
      run: () => {
        handleExecute();
      },
    },
  ]);

  useCompletionItems();
  useModelMarkers(editorRef);
  useHighlightWeldTags(editorRef.current);
  useWeldTagHoverDialog(editorRef.current);

  return (
    <div className="relative flex h-full min-w-0 flex-col overflow-hidden">
      {!isConnected && state.currentModelType === "model" && (
        <div className="absolute inset-0 z-50 ml-px grid place-items-center bg-white dark:bg-gray-800">
          <LoadingSpinner />
        </div>
      )}
      <div className="relative flex h-full grow flex-col overflow-hidden">
        <Editor
          path={state.currentModelId ?? ""}
          theme={isDarkModeEnabled ? "vs-dark" : "vs"}
          defaultValue={getTextState().weldSql}
          loading={<div />}
          language="sql"
          options={editorProps}
          onMount={handleMountEditor}
          onChange={(value) => {
            if (
              ["draft", "tile"].includes(state.currentModelType) &&
              typeof value === "string"
            ) {
              handleChangeSqlQuery(value);
            }
          }}
        />
        <EditorPlaceholderOverlay />
      </div>
      <AutoSaveNotification />
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <TrackRecentModels_Detached />
    </div>
  );
}

const EditorPlaceholderOverlay = memo(() => {
  const { weldSql } = useModelEditorTextState();
  if (!weldSql) {
    return (
      <div
        className="pointer-events-none absolute left-0 top-0 text-gray-300 dark:text-gray-500"
        style={{
          marginTop: "17px",
          marginLeft: "64px",
        }}
      >
        {"select * from {{raw.source.table}}"}
      </div>
    );
  }
  return null;
});
